[class*=i--] {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  transition: stroke .15s ease;
  stroke: $my-color-g;
  fill: none;
  stroke-width: 1.1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}


.i--color-2, [data-icon-parent]:focus
[data-icon-parent]:hover .i--hocus-color-2 {
  stroke: $my-color-g;
}

.i--size-xxxs {
  width: 10px;
  height: 10px;
  stroke-width: 4px;
}

.i--size-xxs {
  width: 12px;
  height: 12px;
  stroke-width: 2px;
}

.i--size-xs {
  width: 16px;
  height: 16px;
  stroke-width: 1.5px;
}

.i--size-s {
  width: 20px;
  height: 20px;
  stroke-width: 1.3px;
}

.i--size-l {
  width: 32px;
  height: 32px;
  stroke-width: .9px;
}

mibp-sandvik-icon[name=cart] {
  .i--size-l {
    margin-top: -4px;
    width: 34px;
    height: 34px;
  }
}

.i--size-xl {
  width: 48px;
  height: 48px;
  stroke-width: .7px;
}

.i--size-xxl {
  width: 64px;
  height: 64px;
  stroke-width: .55px;
}

.i--size-xxxl {
  width: 128px;
  height: 128px;
  stroke-width: .3px;
}

.i--size-xxxxl {
  width: 256px;
  height: 256px;
  stroke-width: .18px;
}

.i--stroke-bold {
  stroke-width: 3px;
}

.i--stroke-medium {
  stroke-width: 2px;
}

.i--margin-right {
  margin-right: .5em;
}

.i--margin-left {
  margin-left: .5em;
}

.i--color-link, [data-icon-parent]:focus .i--hocus-color-link, [data-icon-parent]:hover .i--hocus-color-link {
  stroke: #0069a8;
}

.i--color-black, [data-icon-parent]:focus .i--hocus-color-black, [data-icon-parent]:hover .i--hocus-color-black {
  stroke: #000;
}

.i--color-grey, [data-icon-parent]:focus .i--hocus-color-grey, [data-icon-parent]:hover .i--hocus-color-grey {
  stroke: #767676;
}

.i--color-white, [data-icon-parent]:focus .i--hocus-color-white, [data-icon-parent]:hover .i--hocus-color-white {
  stroke: #fff;
}

.i--color-red, [data-icon-parent]:focus .i--hocus-color-red, [data-icon-parent]:hover .i--hocus-color-red {
  stroke: $my-color-error;
}

.i--color-blue, [data-icon-parent]:focus .i--hocus-color-blue, [data-icon-parent]:hover .i--hocus-color-blue {
  stroke: $my-color-eb2;
}

.i--color-green, [data-icon-parent]:focus .i--hocus-color-green, [data-icon-parent]:hover .i--hocus-color-green {
  stroke: $my-color-success;
}

.i--color-gold, [data-icon-parent]:focus .i--hocus-color-gold, [data-icon-parent]:hover .i--hocus-color-gold {
  stroke: $my-color-g;
}
