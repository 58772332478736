.forms__row {
    margin-bottom: 30px;
    input, select {
        width: 100%;
    }
}

.forms__row {
  &.buttons {
    text-align: right;
    > * {
      margin-left: 10px;
    }
    @media only screen and (max-width: 392px){
      :first-child button {
        margin-bottom: 10px;
      }
    }
  }
}

input[type="text"].small {
  padding: 3px 4px;
  min-height: 37px;
  font-size: 13px;
}

.checkbox > input[disabled] + label {
  filter: grayscale(100%);

}

*[formcontrolname].ng-invalid.ng-touched {

  > mibp-dropdown .custom-select > span {
    border: 1px solid #f36a22;
    background-color: #fffbf9;
    > .single-select {
      border-color: #f36a22 ! important;
      > .input-field input {
        background-color: #fffbf9;
      }
    }
  }
}

.my-form-help-block {
  color: #767676;
  margin-bottom: 26px;
  margin-top: 2px;
}




mibp-permission-picker.ng-invalid .new-permission-list {
  border: 1px solid #f36a22;
  .no-permissions-text {
    background-color: #ff6d00 ! important;
  }
}

.form-help-block {
  margin-top: 4px;
  font-style: italic;
  color: #555;
  min-height: 1em;
  line-height: 1.25em;
}

.forms__fieldset {
  legend {
    border-bottom: 1px solid #ebebeb;
    display: block;
    width: 100%;
    font-weight: 200;
    line-height: 26px;
    margin-bottom: 24px;
  }
}
