/*
 * IE 11 hack to make sure the menu items are not cut into pieces
 * Included from global-styles.scss
 */
@media all and (-ms-high-contrast:none)
{

  *::-ms-backdrop, .my-context-menu__item {
      padding-right: 46px  ! important;
    }
  } /* IE11 */


