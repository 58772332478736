/*

 My Sandvik BODY block (BEM style)

 Will format HTML elements that do not have the BEM classes.
 This is useful for large amount of content or content
 that has been created by users

 <section class="my-body">
  <p>This is a nice paragraph</p>
 </section>

*/

#{$mysandvik}body {
  margin-top: 0;
  margin-bottom: 1.5em;

  p, table, dl, dd, div, section {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.45em;
  }

  &--normal {
    p, table, dl, dd, div, section {
      font-weight: 400;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0.5em;
  }

  h1 {
    @include mysandvik-h1();
  }

  h2 {
    @include mysandvik-h2();
  }

  ul {
    @include mysandvik-ul();
  }

  strong {
    font-weight: 700;
  }

  &--nomargin {
    margin:0;
  }

  picture {
    display: block;
    margin-top: 1em;
  }

}
