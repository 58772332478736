/*
 My Sandvik STATUS LABEL block (BEM style)

 Modifiers
 --critical
 --important

*/

#{ $mysandvik }status-label {
  background-color: #000;
  border-radius: 9px;
  box-sizing: border-box;
  color: #FFF;
  display: inline-block;
  font-size: 10px;
  line-height: 15px;
  max-width: 90px;
  overflow: hidden;
  padding: 4px 9px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  &--critical {
    background: $my-color-error;
  }

  &--important,
  &--warning {
    background: $my-color-vo;
  }

  &--information {
    background: $my-color-eb;
  }
}
