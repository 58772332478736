/* ------------------------------------------------------------ Footer */

.footer {
	position: relative;
	padding-top: 100px;
	padding-bottom: 30px;
	line-height: 25px;
}

.page--light[class] .footer {
	background-color: #fff;
	color: #000;
}

.group.section--dark + .section--light > .footer {
	margin-top: -40px;
}

.footer:before {
	position: absolute;
	top: 60px;
	right: 0;
	left: 0;
	height: 1px;
	content: "";
}

.page--light .footer:before {
	background: #ebebeb;
}

html:not(.kanthal) .footer-polygon {
	padding-top: 300px;
	border: none;
	background: url(#{$assets-images}polygon-footer-1600.svg) center 30px no-repeat;
	background-size: contain;
}

html:not(.kanthal).no-svg .footer-polygon {
	background-image: url(#{$assets-images}polygon-footer-1600.png);
}

html:not(.kanthal) .footer-polygon:before {
	display: none;
}

.footer-left {
	position: relative;
	float: left;
	width: 45%;
}

.footer-right {
	float: right;
	width: 50%;
	text-align: right;
}

/* Statement text + logo */

.statement {
	margin-bottom: 26px;
	padding-top: 76px;
}

.footer-left img {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 137px;
	height: 50px;
}

.statement b {
	font-weight: 400;
}

/* Copyright */

.copyright {
	color: #767676;
}

.copyright a {
	color: #767676;
}

.page--light .copyright a {
	border-bottom-color: #d7d7d7;
	border-bottom-color: rgba(118, 118, 118, 0.3);
}

.copyright a:hover,
.copyright a:focus,
.copyright a:active {
	color: rgb(0, 153, 255);
}

.page--light .copyright a:hover,
.page--light .copyright a:focus,
.page--light .copyright a:active {
	border-bottom-color: #b2e0ff;
	border-bottom-color: rgba(0, 153, 255, 0.3);
}

/* Nav supp */

.nav-supp {
	margin: 75px 0 30px;
}

.nav-supp li {
	display: inline-block;
	margin-left: 12px;
}

.lt-ie8 .nav-supp li {
	display: inline;
	zoom: 1;
}

.nav-supp li a {
	display: block;
	font-size: 15px;
	line-height: 20px;
	text-transform: uppercase;
}

/* Social */

.nav-social {
	margin-bottom: 40px;
}

.nav-social li {
	display: inline-block;
	margin-left: 16px;
	vertical-align:top;
}

.lt-ie8 .nav-social li {
	display: inline;
	zoom: 1;
}

.nav-social li a,
.nav-social li button {
	display: block;
	position: relative;
	width: 50px;
	height: 50px;
	border: none;
	background: #000;
	line-height: 50px;
	transition:all 0.2s ease;
}

/* [1] Note that the > is neccessary because svg with use is technically an SVG within
   an SVG.
   [2] Not entirely neccesary with the > but it's prevents it from affecting the visually
   hidden labels */
.nav-social li a > svg, /* [1] */
.nav-social li button > span { /* [2] */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.nav-social[class] li button.link > span {
	border-bottom: none;
}

.nav-social li a > span,
.nav-social li button span > span {
	position:absolute;
	left:-9999px;
}

.nav-social li button:hover span,
.nav-social li button:focus span {
	border-color:transparent;
}

.nav-social li a:hover,
.nav-social li a:focus,
.nav-social li button:hover,
.nav-social li button:focus {
	opacity: 0.75;
}

@media only screen and (min-width: 3000px) {
	html:not(.kanthal) .footer-polygon {
		padding-top: 400px;
		background-size: 100% auto;
	}
}

@media only screen and (max-width: 1200px) {
	html:not(.kanthal) .footer-polygon {
		padding-top: 250px;
	}
}

@media only screen and (max-width: 1050px) {
	.footer {
		text-align: center;
	}

	html:not(.kanthal) .footer-polygon {
		padding-top: 170px;
		background-position: 50% 50px;
	}

	.footer-left,
	.footer-right {
		float: none;
		width: auto;
		text-align: inherit;
	}

	.statement {
		max-width: 80%;
		margin: 0 auto 30px;
		padding: 0;
	}

	.footer-left img {
		display: none;
	}

	.nav-supp {
		margin-top: 30px;
	}

	.nav-supp li {
		margin-right: 8px;
		margin-left: 8px;
	}

	.nav-supp li a {
		font-size: 14px;
	}

	.copyright {
		margin-top: 30px;
	}

	.nav-social li {
		margin-right: 8px;
		margin-left: 8px;
	}
}

@media only screen and (max-width: 1024px) {
	.footer-app {
		display: none;
	}
}

@media only screen and (max-width: 550px) {
	html:not(.kanthal) .footer-polygon {
		padding-top: 130px;
	}

	.statement {
		max-width: none;
	}

	.nav-social li {
		margin-bottom: 8px;
	}
}

@media print {
	.footer {
		clear:both;
		margin: 0;
		padding: 1.5em 0;
		border-top:1px solid #000;
		font-size: 9pt;
		line-height: 1.4;
	}

	html:not(.kanthal) .footer-polygon,
	html:not(.kanthal).no-svg .footer-polygon {
		background: none;
	}

	.footer img {
		display: none;
	}

	.footer-left {
		float: none;
		width: auto;
	}

	.footer-right {
		display: none;
	}

	.statement {
		margin-bottom: 15px;
		padding: 0;
	}

	.statement b {
		color: #000;
	}
}
