.media\@xxs,
.media\@xxs-only {
  // Hide if not xss
  @include media-not-xxs() {
    display: none ! important;
  }
}

.media\@not-xxs {
  // Hide when xss
  @include media-xxs-only() {
    display: none ! important;
  }
}


.media\@xs,
.media\@xs-only {
  // Hide if not xs
  @include media-not-xs() {
    display: none ! important;
  }
}

.media\@not-xs {
  // Hide when xs
 @include media-xs-only() {
   display: none ! important;
 }
}


.media\@xs-and-larger {
  // Hide xxs
  @include media-xxs-only {
    display: none ! important;
  }
}

.media\@xs-and-smaller {
  @include media-s-and-larger() {
    display: none ! important;
  }
}


.media\@s,
.media\@s-only {
  // Hide if not s
  @include media-not-s {
    display: none ! important;
  }
}

.media\@not-s {
  // Hide if s
  @include media-s-only {
    display: none ! important;
  }
}

.media\@s-and-larger {
  // Hide for xs and smaller
  @include media-xs-and-smaller {
    display: none ! important;
  }
}

.media\@s-and-smaller {
  // Hide for m and larger
  @include media-m-and-larger {
    display: none ! important;
  }
}


.media\@m,
.media\@m-only {
  // Hide if not m
  @include media-not-m {
    display: none ! important;
  }
}

.media\@not-m {
  // Hide for m
  @include media-m-only {
    display: none ! important;
  }
}

.media\@m-and-larger {
  // Hide for s and smaller
  @include media-s-and-smaller {
    display: none ! important;
  }
}

.media\@m-and-smaller {
  // Hide for l and larger
  @include media-l-and-larger {
    display: none ! important;
  }
}


.media\@l,
.media\@l-large {
  // Hide for all but l
  @include media-not-l {
    display: none ! important;
  }
}

.media\@not-l {
  // Hide if l
  @include media-l-only {
    display: none ! important;
  }
}

.media\@l-and-larger {
  // Hide for m and smaller
  @include media-m-and-smaller {
    display: none ! important;
  }
}

.media\@l-and-smaller {
  // Hide for xl
  @include media-xl-only {
    display: none ! important;
  }
}



.media\@xl,
.media\@xl-large {
  // Hide for all but xl
  @include media-not-xl {
    display: none ! important;
  }
}

.media\@not-xl {
  // Hide if xl
  @include media-xl-only {
    display: none ! important;
  }
}

.media\@xl-and-larger {
  // Hide if not xl
  @include media-not-xl {
    display: none ! important;
  }
}
