.html-content {

  h1 {
    @include mysandvik-h1();
  }

  h2 {
    @include mysandvik-h2();
  }

  h2 {
    @include mysandvik-h3();
  }

  strong {
    font-weight: bold;
  }

}
