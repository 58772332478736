/* ------------------------------------------------------------ Group */

.group-inner {
	position: relative;
	max-width: 1310px;
	margin: auto;
	padding: 0 80px;
}

.group-inner:after {
	display:table;
	clear:both;
	content:"";
}

@media only screen and (min-width: 1051px) {
	.body--wide .group-inner {
		padding: 0 64px;
	}
}

@media only screen and (max-width: 1050px) {
	.group-inner {
		padding: 0 20px;
	}
}

@media only screen and (max-width: 500px) {
	.group-inner {
		padding: 0 15px;
	}
}

@media print {
	.group-inner {
		padding: 0;
	}
}
