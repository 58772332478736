
/**
 * Strippe down no-nonsense data table
 */
table.new-data-table {
  @include mysandvik-reset-table();

  td, th {
    padding: 8px 4px;
    vertical-align: middle;
  }

  thead {
    th {
      font-weight: bold;
      border-bottom: 2px solid #dbdbdb;
    }
  }


  tbody {
    tr {
      &:hover {
        background-color: #fafafa;
      }
      td, th {
        border-bottom: 1px solid #dbdbdb;
      }
    }
  }
  // x

}
