@import '../variables.scss';

@page {
	margin: 15mm 20mm;
	size: auto;
}

* {
	margin: 0;
	padding: 0;
	border: 0;
	box-sizing: border-box;
}

html {
	&.ux-version--v2 {
		font-family: $my-font-family-text;
		line-height: 1.5rem;
	}

	&.ux-version--v1 {
		font: 200 18px/26px "Sandvik Sans", Helvetica, Arial, sans-serif;
	}

	/* Thinner font rendering */
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;

	-webkit-text-size-adjust: 100%;
}

@media only screen and (max-width: 1050px) {
	html {
		font-size: 16px;
		line-height: 24px;
	}
}

@media print {
	html,
	body {
		background: #fff;
		color: #000;
	}
}

/* Avoid line-height issues caused by default rendering of sub and sup elements */
sub,
sup {
	position: relative;
	height: 0;
	line-height: 1;
	vertical-align: baseline;
}

sup {
	bottom: 1ex;
	font-size: 0.7em;
}

sub {
	top: 0.5ex;
}

code,
kbd,
pre {
	font-size: 14px;
	font-family: Monaco, Courier, "Courier New", Tahoma;
	line-height: 22px;
}

pre {
	overflow-x: scroll;
	padding: 10px 15px;
	border: 1px solid $my-color-border;
}

a,
a:visited {
	color: $my-color-text;
	text-decoration: underline;
	text-decoration-color: $my-color-border;
	text-underline-offset: 4px;
	transition-duration: .15s;
	transition-property: color,background-color,border-color,text-decoration-color,fill,stroke;
	transition-timing-function: cubic-bezier(.1,.2,.4,.9);
}

a:hover,
a:focus,
a:active {
	text-decoration-color: $my-color-cb;
}

p:empty {
	margin: 0;
}

b,
strong {
	font-weight: 400;
}

i,
em {
	font-style: italic;
}

abbr {
	border-bottom: 1px dotted $my-color-border-dark;
	cursor: help;
}

code {
	font-size: 0.8em;
}

iframe {
	padding: 0;
	outline: none;
	border: none;
}

.text address,
.text dl,
.text ol,
.text p,
.text pre,
.text table,
.text ul,
.text figure {
	margin-bottom: 5px;
}

.text form {
	margin-bottom: 40px;
}

@media only screen and (max-width: 1050px) {

	.text address,
	.text dl,
	.text ol,
	.text p,
	.text pre,
	.text table,
	.text ul,
	.text figure {
		margin-bottom: 24px;
	}

	.text form {
		margin-bottom: 32px;
	}
}

/* Lists
---------------------------------------- */

ul {
	list-style: none;
}

.text ul li {
	position: relative;
	margin-bottom: 13px;
	padding-left: 25px;
}

.text ul li::before {
	position: absolute;
	margin-right: 10px;
	margin-left: -25px;
	content: "– ";
}

.page--light .text ul li::before {
	color: $my-color-text;
}

.text ul li ul,
.text ul li ol {
	margin-top: 15px;
	margin-bottom: 0;
}

ol {
	list-style: none;
}

.text ol {
	counter-reset: li;
}

.text ol>li {
	position: relative;
	margin-bottom: 13px;
	padding-left: 28px;
}

.text ol>li::before {
	position: absolute;
	left: 0;
	color: $my-color-text;
	content: counter(li)".";
	counter-increment: li;
	text-align: center;
}

.text ol li ol,
.text ol li ul {
	margin-top: 15px;
	margin-bottom: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
	margin-bottom: 0;
}

.text dt {
	color: #767676;
	font-weight: 400;
	font-size: 15px;
	text-transform: uppercase;
}

.text dd {
	margin-bottom: 15px;
}

ul.cols-2,
ol.cols-2 {
	-moz-column-count: 2;
	-webkit-column-count: 2;
	column-count: 2;
	-moz-column-gap: 40px;
	-webkit-column-gap: 40px;
	column-gap: 40px;
}

ul.cols-3,
ol.cols-3 {
	-moz-column-count: 3;
	-webkit-column-count: 3;
	column-count: 3;
	-moz-column-gap: 40px;
	-webkit-column-gap: 40px;
	column-gap: 40px;
}

ul.cols-4,
ol.cols-4 {
	-moz-column-count: 4;
	-webkit-column-count: 4;
	column-count: 4;
	-moz-column-gap: 40px;
	-webkit-column-gap: 40px;
	column-gap: 40px;
}

@media only screen and (max-width: 1050px) {

	.text ul li {
		margin-bottom: 10px;
		padding-left: 20px;
	}

	.text ul li::before {
		margin-left: -20px;
	}

	.text ul li ul {
		margin-top: 10px;
	}

	.text ol>li {
		margin-bottom: 10px;
		padding-left: 26px;
	}

	.text ol li ol {
		margin-top: 10px;
	}

	.text dt {
		font-size: 13px;
	}
}

@media only screen and (max-width: 900px) {
	ul.cols-2,
	ol.cols-2,
	ul.cols-3,
	ol.cols-3 {
		-moz-column-count: 1;
		-webkit-column-count: 1;
		column-count: 1;
	}
}

/* Blockquote
---------------------------------------- */

blockquote {
	position: relative;
	margin: 0 0 10px;
	padding: 14px 0 0 0;
	color: rgb(255, 106, 0);
	font-size: 26px;
	line-height: 30px;
}

a>blockquote:after {
	color: #09f;
	font-weight: 400;
	font-size: 14px;
	content: "Read more";
	text-transform: uppercase;
	-webkit-transition: color 0.162s ease;
	transition: color 0.162s ease;
}

a>blockquote:after {
	color: #1a617f;
}

[lang="sv"] a>blockquote:after {
	content: "Läs mer";
}

a:hover>blockquote:after,
a:focus>blockquote:after {
	color: $my-color-text;
}

blockquote:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 33%;
	height: 1px;
	background: #ff6a00;
	content: "";
	-webkit-transition: background 0.162s ease;
	transition: background 0.162s ease;
}

blockquote :first-child:before {
	content: "“";
}

blockquote :last-child:after {
	content: "”";
}

[lang="sv"] blockquote :first-child:before {
	content: "”";
}

.blockquote-cite {
	font-size: 14px;
	line-height: 26px;
	text-transform: uppercase;
}

.s-text--large .blockquote-cite {
	font-size: 18px;
	line-height: 24px;
}

.blockquote-cite img {
	max-width: 200px;
	max-height: 40px;
}

@media only screen and (max-width: 1050px) {
	blockquote {
		padding-top: 10px;
		font-size: 20px;
		line-height: 26px;
	}

	blockquote:before {
		height: 1px;
	}

	.article blockquote,
	.s-text blockquote {
		margin: 25px 0;
	}

	blockquote:after,
	.no-generatedcontent blockquote span {
		font-size: 14px;
		line-height: 20px;
	}
}

@media only screen and (max-width: 480px) {
	a>blockquote:after {
		font-size: 12px;
		line-height: 16px;
	}
}

/* Tables
---------------------------------------- */

table {
	width: 100%;
	border-spacing: 0;
	border-collapse: collapse;
	border-top: 1px solid #000;
}

table.tight,
table.tight-2 {
	font-size: 15px;
	line-height: 20px;
}

caption {
	margin-bottom: 5px;
	font-weight: 600;
	font-size: 18px;
	text-align: left;
}

table.tight caption,
table.tight-2 caption {
	font-size: 15px;
}

td,
th {
	vertical-align: top;
}

th,
.th {
	padding: 3px 8px;
	border-bottom: 1px solid #000;
	font-weight: 400;
	text-align: left;
}

td {
	padding: 3px 8px;
	border-bottom: 1px solid #bbb;
}

tr.gap td {
	padding-top: 1.75em;
}

td[align="right"],
th[align="right"] {
	text-align: right;
}

td[align="center"],
th[align="center"] {
	text-align: center;
}

table.tight th,
table.tight td,
table.tight-2 th,
table.tight-2 td {
	padding-right: 5px;
	padding-left: 5px;
}

tbody tr th {
	border-bottom-color: #bbb;
}

tbody tr:last-child td,
tbody tr:last-child th {
	border-bottom-color: #000;
}

tfoot td,
tfoot th {
	border-bottom-color: #000;
	font-weight: 400;
}

.Nottabell_Siffra-summa,
.Nottabell_Siffra,
.Nottabell_Top-right,
.Financial-statements_Not {
	text-align: right;
}

.Nottabell_Top-centered {
	text-align: center;
}

.Nottabell_Siffra,
.Nottabell_Siffra-summa {
	white-space: nowrap;
}

.Grundl-ggande-tabell td:empty {
	height: 32px;
}

/* Table notes */

.table-notes,
.footnotes,
.footnote {
	font-size: 14px;
	line-height: 18px;
}

table+.table-notes,
table+.footnotes,
table+.footnote,
.article-table+.table-notes,
.article-table+.footnotes,
.article-table+.footnote {
	margin-top: -20px;
}

p.table-notes,
p.footnotes,
p.footnote {
	margin-bottom: 30px;
}

/* Legal text */

.legal-text {
	color: #767676;
	font-size: 16px;
}

/* Scrollable tables */

.overthrow-enabled .scrollable-table {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.overthrow-enabled .scrollable-table:after {
	position: absolute;
	top: 0;
	left: 100%;
	z-index: 10;
	width: 50px;
	height: 100%;
	border-radius: 8px 0 0 8px / 125px 0 0 125px;
	box-shadow: -3px 0 5px rgba(0, 0, 0, 0.32);
	content: "";
}

.overthrow-enabled .scrollable-table.shadow-left:before {
	position: absolute;
	top: 0;
	right: 100%;
	z-index: 10;
	width: 50px;
	height: 100%;
	border-radius: 0 8px 8px 0 / 0 125px 125px 0;
	box-shadow: 3px 0 5px rgba(0, 0, 0, 0.32);
	content: "";
}

.overthrow-enabled .scrollable-table.no-shadow-right:after {
	display: none;
}

.overthrow-enabled .scrollable-table>.inner {
	width: 100%;
	overflow: auto;
	margin: 0 0 15px;
	padding: 15px 0 0 0;

	-webkit-overflow-scrolling: touch;
}

/* Table column highlight */

tbody:first-child tr:first-child .table-column-highlight,
caption+tbody tr:first-child .table-column-highlight,
thead tr:first-child th.table-column-highlight,
thead tr:first-child td.table-column-highlight {
	border-top: 2px solid $my-color-border;
}

th.table-column-highlight,
td.table-column-highlight {
	border-right: 2px solid $my-color-border;
	border-left: 2px solid $my-color-border;
}

tfoot td.table-column-highlight,
thead+tbody tr:last-child td.td.table-column-highlight {
	border-bottom: 2px solid $my-color-border;
}

tbody tr.table-section-divider td,
tbody tr.table-section-divider th {
	border-bottom-color: #000;
}

/* HR
---------------------------------------- */

hr {
	height: 1px;
	margin: 26px 0;
}

.page--light hr {
	background: #ebebeb;
}

/* Annual report export class
---------------------------------------- */
.Light-Italic {
	font-weight: 200;
	font-style: italic;
}

/* Headings
---------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 200; //TODO: This should probably be changed to 400 with new branding
	font-size: inherit;
	line-height: 26px;
}

.heading-type-1 {
	margin-bottom: 20px;
	color: $my-color-text;
	font-size: 40px;
	line-height: 45px;
}

.heading-type-1 a {
	border-bottom: none;
}

.heading-type-1 a:after {
	display: inline-block;
	width: 40px;
	height: 30px;
	margin-top: 1px;
	margin-left: 15px;
	background:url("#{$assets-images}arrow-right-blue-20x15.svg") 0 0 no-repeat;
	background-size: 40px 30px;
	content: "";
	vertical-align: -2px;
}

.heading-type-2 {
	margin-bottom: 20px;
	color: $my-color-text;
	font-size: 30px;
	line-height: 35px;
}

.heading-type-3 {
	margin-bottom: 30px;
	color: $my-color-text;
	font-size: 60px;
	line-height: 65px;
}

.heading-type-4 {
	margin-bottom: 30px;
	color: $my-color-text;
	font-size: 50px;
	line-height: 55px;
}

.heading-type-5 {
	margin-bottom: 10px;
	font-weight: 400;
	font-size: 16px;
}

.heading-type-1 span,
.heading-type-2 span,
.heading-type-3 span,
.heading-type-4 span {
	display: block;
	color: $my-color-text;
}

/* Class added with JS if heading spans anything other than 2 rows */

.article-heading-h1,
.article h1,
.article .article-heading-h1 {
	margin-bottom: 26px;
	color: $my-color-text;
	font-weight: 200;
	font-size: 40px;
	line-height: 45px;
}

.article-heading-h2,
.article h2,
.article .article-heading-h2 {
	margin-bottom: 3px;
	color: $my-color-text;
	font-weight: 200;
	font-style: normal;
	font-size: 22px;
	line-height: 26px;
	text-transform: none;
}

.article-heading-h2,
.article .article-heading-h2 {
	margin-bottom: 10px;
}

.article-heading-h3,
.article h3,
.article .article-heading-h3 {
	color: $my-color-text;
	font-weight: 400;
	font-style: normal;
	font-size: 18px;
	line-height: 26px;
	text-transform: none;
}

.page--light .article-heading-h3,
.page--light .article h3 {
	color: $my-color-text;
}

.article-heading-h4,
.article-heading-h5,
.article-heading-h6,
.article h4,
.article h5,
.article h6 {
	color: $my-color-text;
	font-weight: 200;
	font-style: italic;
	font-size: 18px;
	line-height: 26px;
	text-transform: none;
}

.article .no-caps {
	text-transform: none;
}

@media only screen and (max-width: 1050px) {

	.heading-type-1,
	.heading-type-3 {
		margin-bottom: 12px;
		font-size: 35px;
		line-height: 40px;
	}

	.heading-type-2 {
		margin-bottom: 12px;
		font-size: 25px;
		line-height: 30px;
	}

	.article-heading-h1,
	.article h1 {
		margin-bottom: 18px;
		font-size: 30px;
		line-height: 35px;
	}

	.article-heading-h2,
	.article h2 {
		margin-bottom: 2px;
		font-size: 20px;
		line-height: 24px;
	}

	.article-heading-h2,
	.article .article-heading-h2 {
		margin-bottom: 10px;
	}

	.article-heading-h3,
	.article-heading-h4,
	.article-heading-h5,
	.article-heading-h6,
	.article h3,
	.article h4,
	.article h5,
	.article h6 {
		font-size: 16px;
		line-height: 24px;
	}
}

@media only screen and (max-width: 500px) {

	.heading-type-1,
	.heading-type-3,
	.article-heading-h1,
	.article h1 {
		margin-bottom: 12px;
		font-size: 25px;
		line-height: 30px;
	}

	.heading-type-1 a:after {
		width: 30px;
		height: 25px;
		margin-left: 10px;
		background-size: 25px 20px;
		vertical-align: -6px;
	}
}

@media print {
	.article-heading-h1,
	.article h1 {
		margin-bottom: 20px;
		font-size: 35px;
		line-height: 40px;
	}
}

/* Form elements
---------------------------------------- */

legend {
	display: table;
	/* Enable line-wrapping in IE8+ */
	white-space: normal;
	/* Enable line-wrapping in other browsers */
}

label {
	display: block;
	margin-bottom: 5px;
	font-weight: 700;
	line-height: 20px;
}

button,
input,
select,
textarea {
	font: inherit;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: 15px;
}

textarea {
	display: block;
	resize: vertical;
}

input[type="text"],
input[type="datetime-local"],
input[type="time"],
input[type="date"],
input[type="url"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="password"],
input[type="search"],
textarea {
	min-height: 42px;
	padding: 5px 10px;
	outline: none;
	border: 1px solid;
	border-radius: 3px;
	font-size: 1rem;
	-webkit-appearance: none;
}

.page--light input[type="text"],
.page--light input[type="datetime-local"],
.page--light input[type="date"],
.page--light input[type="time"],
.page--light input[type="url"],
.page--light input[type="email"],
.page--light input[type="tel"],
.page--light input[type="number"],
.page--light input[type="password"],
.page--light input[type="search"],
.page--light textarea {
	border-color: $my-color-n3;
	background-color: $my-color-white;
	color: $my-color-cb;
}

.page--light {

	input,
	textarea {

		&.ng-invalid.ng-dirty,
		&.ng-invalid.ng-touched {
			border: 1px solid $my-color-vo;
			background-color: #fffbf9;
		}

	}
}

.page--light {
	form.submitted {

		input,
		textarea {
			&.ng-invalid:not(.ng-touched):not(.ng-dirty) {
				border: 1px solid $my-color-vo;
				background-color: #fffbf9;
			}
		}
	}
}


mibp-datepicker.ng-invalid.ng-dirty,
mibp-datepicker.ng-invalid.ng-touched {

	input,
	textarea {
		border: 1px solid $my-color-vo;
		background-color: #fffbf9;
	}
}


form.submitted {
	mibp-datepicker.ng-invalid:not(.ng-touched):not(.ng-dirty) {

		input,
		textarea {
			border: 1px solid $my-color-vo;
			background-color: #fffbf9;
		}
	}
}


*::-moz-selection {
	background: $my-color-eb2;
	color: #fff;
}

*::selection {
	background: $my-color-eb2;
	color: #fff;

}

.validation-message {
	font-style: normal;
	padding: 3px 8px;
	border: 1px solid #ccc;
	border-left: 6px solid #ff9900;
	color: $my-color-text;
	display: inline-block;
	margin-top: 4px;
}

input[type="text"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
textarea:focus {
	border-color: $my-color-n3;
}

input[type="text"][disabled],
input[type="datetime-local"][disabled],
input[type="date"][disabled],
input[type="time"][disabled],
input[type="date"][disabled],
input[type="text"][readonly],
input[type="url"][disabled],
input[type="email"][disabled],
input[type="tel"][disabled],
input[type="number"][disabled],
input[type="password"][disabled],
input[type="search"][disabled],
textarea[disabled] {
	border-color: rgb(205, 205, 205);
	background: rgb(248, 248, 248);
	color: #767676;
	cursor: not-allowed;
}

/* Reset button look in webkit */
input[type="reset"],
input[type="button"],
input[type="submit"],
button {
	padding: 5px;
	-webkit-appearance: none;
}

/* Reset search input look in webkit, making it possible to style it as a normal text input */
input[type="search"] {
	-webkit-appearance: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
	display: none;
}

/* Placeholder styling for Gecko 18- */
input:-moz-placeholder,
textarea:-moz-placeholder,
/* ... and Gecko 19+ */
input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #808080;
	font-weight: 200;
	opacity: 1;
	/* No semi-transparent text, please */
}

/* Placeholder styling for webkit */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #808080;
	font-weight: 200;
}

/* Placeholder styling for IE10 */
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #808080;
	font-weight: 200;
}

/* Reset extra padding on gecko buttons */
button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner {
	padding: 0;
	border: 0;
}

@media only screen and (max-width: 1050px) {
	label {
		font-size: 13px;
	}

	input:not([type]),
	input[type="text"],
	input[type="url"],
	input[type="email"],
	input[type="tel"],
	input[type="number"],
	input[type="password"],
	input[type="search"],
	textarea {
		min-height: 38px;
		font-size: 16px;
	}
}

form .alt {
	display: inline-block;
	margin: 0 15px 0 0;
}

fieldset {
	margin: 0;
	padding: 0;
	border: none;
}

legend,
.heading-style-legend {
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
	line-height: 20px;
}

legend em,
.heading-style-legend em {
	color: #808080;
	font-weight: 200;
	font-style: normal;
}

label>span,
label>em {
	margin: 0;
	color: #808080;
	font-weight: 200;
	font-style: normal;
	margin-left: 8px;
}

.disabled label {
	color: #999;
}

input[type="submit"] {
	width: auto !important;
}

.text textarea,
.s-text textarea {
	height: 120px;
}

.checkbox,
.radio {
	white-space: nowrap;
}

.checkbox input,
.radio input {
	vertical-align: middle;
}

.checkbox label,
.radio label {
	margin-left: 4px;
	font-size: inherit;
	vertical-align: middle;
	white-space: normal;
}

.alt label {
	display: inline;
	font-weight: 200;
}

.text.error label
.error legend {
	color: $my-color-error;
}

.text.error input,
.text.error textarea {
	/* Styling of error input */
	border-color: rgb(220, 70, 60);
}

.no-border legend {
	padding: 0;
}

@media only screen and (max-width: 1050px) {
	legend {
		font-size: 13px;
	}
}

@media print {
	.text,
	.radio,
	.checkbox,
	.select {
		margin: 0 0 0.25em;
	}

	.text input {
		width: 95%;
	}

	.text textarea {
		width: 95%;
		height: 10em;
	}

	.pwd input {
		width: 6em;
	}

	.control-group {
		margin: 1em 0;
	}

	.checkbox,
	.radio {
		white-space: nowrap;
	}

	.checkbox input,
	.radio input {
		vertical-align: middle;
	}

	.checkbox label,
	.radio label {
		display: inline;
		margin-left: 0.5em;
		vertical-align: middle;
	}
}

/* Custom radio/checkboxes
-------------------------------------------------------------- */
.checkbox,
.radio {
	position: relative;
	margin-bottom: 10px;
}

.checkbox:last-child,
.radio:last-child {
	margin-bottom: 0;
}

.checkbox input,
.radio input {
	position: absolute;
	left: -9999px;
}

.checkbox input:focus,
.radio input:focus {
	outline: none;
}

.checkbox label,
.radio label {
	display: inline-block;
	position: relative;
	z-index: 1;
	margin: 0;
	padding: 0 0 0 30px;
	font-weight: 200;
	font-size: 18px;
	text-transform: none;
	cursor: pointer;
	-webkit-transition: none;
	transition: none;
}

.radio input:disabled+label {
	filter: grayscale(1);
	opacity: 0.4;
}

.checkbox label {
	background:url("#{$assets-images}custom-checkbox-gray.svg") 0 0 no-repeat;
}

.no-svg .checkbox label {
	background-image:url("#{$assets-images}custom-checkbox.png");
}

.radio label {
	background:url("#{$assets-images}custom-radio-black.svg") 0 0 no-repeat;
}

.no-svg .radio label {
	background-image:url("#{$assets-images}custom-radio.png");
}

.checkbox label:hover,
.checkbox input:focus+label,
.radio label:hover,
.radio input:focus+label {
	background-position: 0 -200px;
}

.checkbox input:checked+label,
.radio input:checked+label {
	background-position: 0 -400px;
}

.checkbox input:checked+label:hover,
.radio input:checked+label:hover,
.no-touch .checkbox input:checked:focus+label,
.no-touch .radio input:checked:focus+label {
	background-position: 0 -600px;
}

.touch .checkbox input:checked+label,
.touch .radio input:checked+label {
	background-position: 0 -400px;
}

@media only screen and (max-width: 1050px) {
	.checkbox:last-child,
	.radio:last-child {
		margin-bottom: 20px;
	}

	.checkbox label,
	.radio label {
		font-size: 16px;
	}
}

/* Columns
================================================================================ */

.row {
	margin: 0 -10px 26px;
}

.row:after {
	display: table;
	clear: both;
	content: "";
}

.col {
	float: left;
	width: 100%;
	/* The default width of a column corresponds to the .full width */
	padding: 0 10px;
}

.col.small {
	width: 25%;
}

.col.smallmedium {
	width: 33.333333%;
}

.col.medium {
	width: 50%;
}

.col.mediumlarge {
	width: 66.666666%;
}

.col.large {
	width: 75%;
}

.col hr {
	height: 1px;
	margin: 18px 0;
	background: #ebebeb;
}

.col.hr,
.col.full {
	width: 100%;
}


@media only screen and (max-width: 700px) {
	.row {
		margin-bottom: 0;
	}

	.col,
	.col.small,
	.col.smallmedium,
	.col.medium,
	.col.mediumlarge,
	.col.large,
	.col.full {
		width: 100%;
		margin-bottom: 20px;
	}

	.col hr {
		margin: 10px 0;
	}
}

/* Form structure: rows and columns
------------------------------------- */

form .col {
	padding: 0 10px;
	/* Different gutter width than for general columns */
}

.row .text input,
.row textarea {
	width: 100%;
}

/* Images
------------------------------------- */

img {
	display: block;
	max-width: 100%;
	height: auto;
	border: none;
}

.center {
	text-align: center;
}

.left,
.right {
	float: left;
	width: auto;
	margin: 5px 35px 18px 0;
}

.right {
	float: right;
	margin: 5px 0 18px 35px;
}

img.fullwidth,
img.fullwidth-dec,
span.fullwidth img,
span.fullwidth-dec img {
	display: block;
	margin: 5px 0 18px;
}

img.dec,
img.fullwidth-dec,
span.dec img,
span.fullwidth-dec img {
	padding: 4px;
	border: 1px solid #cfcfcf;
	background: #fff;
}

@media only screen and (max-width: 700px) {

	.left {
		max-width: 33%;
	}
}

@media only screen and (max-width: 500px) {

	.left {
		float: none;
		max-width: none;
		width: 100%;
	}
}

/* Global classes
================================================================================ */

/* Easy clearing of floats.
 * See http://www.css-101.org/articles/clearfix/latest-new-clearfix-so-far.php
------------------------------------- */

.cf:after {
	display: table;
	clear: both;
	content: "";
}


/* Structural elements.
 * Not visible when CSS is enabled but visible for screen readers
 *
 * 1. position:absolute has issues when inside an overflow scrolling element.
------------------------------------- */

.structural,
.js .js-structural {
	position: fixed;
	/* [1] */
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0;
	border: 0;
}

/* Hidden elements. Hidden even for screen readers
------------------------------------- */

.hidden {
	display: none !important;
	visibility: hidden;
}

/* Clear elements. Used by content editors in TinyMCE
------------------------------------- */

.clear {
	clear: both;
}

/* ornamental "or"
------------------------------------- */

.or {
	margin: 0 30px;
	color: #767676;
	font-style: italic;
}

.or:before {
	content: "– ";
}

.or:after {
	content: " –";
}

/* Utility classes for margin top/bottom
------------------------------------- */

.↓[class][class] {
	margin-bottom: 18px;
}

.↓↓[class][class] {
	margin-bottom: 36px;
}

.↓↓↓[class][class] {
	margin-bottom: 54px;
}

.↑[class][class] {
	margin-top: 18px;
}

.↑↑[class][class] {
	margin-top: 36px;
}

.↑↑↑[class][class] {
	margin-top: 54px;
}

.-↑[class][class] {
	margin-top: -18px;
}

.-↑↑[class][class] {
	margin-top: -26px;
}

.-↑↑↑[class][class] {
	margin-top: -36px;
}

@media only screen and (max-width: 1050px) {
	.↓[class][class] {
		margin-bottom: 15px;
	}

	.↓↓[class][class] {
		margin-bottom: 30px;
	}

	.↓↓↓[class][class] {
		margin-bottom: 45px;
	}

	.↑[class][class] {
		margin-top: 15px;
	}

	.↑↑[class][class] {
		margin-top: 30px;
	}

	.↑↑↑[class][class] {
		margin-top: 45px;
	}
}

@media screen {
	.hide-screen {
		display: none ! important;
	}
}

@media print {
	.hide-print {
		display: none ! important;
	}
}
