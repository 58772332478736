@import '../variables.scss';

@font-face {
  font-family: 'Material Icons Rounded';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/material-fonts/MaterialSymbolsRounded[FILL\,GRAD\,opsz\,wght].woff2') format('woff2'),
       url('/assets/material-fonts/MaterialSymbolsRounded[FILL\,GRAD\,opsz\,wght].ttf') format('truetype')
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/material-fonts/MaterialSymbolsOutlined[FILL\,GRAD\,opsz\,wght].woff2') format('woff2'),
       url('/assets/material-fonts/MaterialSymbolsOutlined[FILL\,GRAD\,opsz\,wght].ttf') format('truetype')
}

@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/material-fonts/MaterialSymbolsSharp[FILL\,GRAD\,opsz\,wght].woff2') format('woff2'),
       url('/assets/material-fonts/MaterialSymbolsSharp[FILL\,GRAD\,opsz\,wght].ttf') format('truetype')
}

.material-icon {
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Adjust the size as needed */
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  color: $my-color-cb;
  font-family: 'Material Icons Rounded';
  &--outlined {
    font-family: 'Material Icons Outlined';
  }

  &--sharp {
    font-family: 'Material Icons Sharp';
  }

  &--large {
    font-size: 32px;
  }
  &--small {
    font-size: 16px;
  }
  &--xxxlarge{
    font-size: xxx-large;
  }
  &--xxlarge{
    font-size: xx-large;
  }
  &--xlarge{
    font-size: x-large;
  }
  &--medium{
    font-size: 24px;
  }

  &--red {
    color: $my-color-rd;
  }

  &--white {
    color: $my-color-white;
  }

  &--orange {
    color: $my-color-vo2;
  }

  &--green {
    color: $my-color-success;
  }

  &--blue {
    color: $my-color-eb2;
  }

  &--gold {
    color: $my-color-g;
  }

  &--spin {
    animation-name: spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }



  font-variation-settings:
  'FILL' 0,
  'wght' 250,
  'GRAD' 0,
  'opsz' 20;

  &--fill {
    font-variation-settings:
    'FILL' 1,
    'wght' 250,
    'GRAD' 0,
    'opsz' 20
  }

  &--strong {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 20
  }
}


@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
