// _tile_cart-details.scss

/* cart active details*/
#cart-active-details .list-item-icon {
    display: block;
    width: 30px;
}
#cart-active-details .column-2 {
    float: left;
}
#cart-active-details .column-3 {
    float: right;
    text-align: right;
}

.cart-details-tile {
  & h3 {
    margin: 0;
    word-wrap: break-word;
  }
}

.cart-details-tile__item {
  overflow: hidden;
  padding: 9px 0 18px 0;
  &:first-of-type {
    margin-top: 9px;
  }
}

.cart-details-tile__item__key {
//  display: block; / breaks the cart summary. Bug 86885:BUG: [20/4] Total in line 
   text-transform: uppercase;
}

.cart-details-tile__item__value {
  float: right;
  font-weight: bold;
  text-align: right;
}

.cart-details-tile__action {
  margin-top: 36px;
  & .button {
    width: 100%;
  }
  & > * + * {
    margin-top: 18px;
  }
}

.cart-details-tile--subtotal,
.cart-details-tile--weight,
.cart-details-tile--number-of-items,
.cart-details-tile--availability {
}

.cart-details-tile--owner {
    color: #999;
    font-size: 90%;
}
