

@mixin mysandvik-ul {
  margin: 1em 0 0 0;
  padding-left: 25px;
  margin-bottom: 25px;
  font-size: 1rem;
  font-weight: 200;

  li {
    margin-bottom: 0.25rem;
  }

  li:before {
    position: absolute;
    margin-right: 10px;
    margin-left: -25px;
    content: "- ";
  }

}


ul#{$mysandvik}list {
  @include mysandvik-ul();
}
