@media screen {
  .my-sandvik-layout {
    &--new {
      height: 100vh;
      overflow-y: hidden;

    }
  }
}

#main-wrapper {
  position: relative;
  -webkit-backface-visibility: hidden;
}

@media print {
  .my-sandvik-content-panel {
    overflow: hidden;
  }

  .column,
  .columns {
    margin-left: 0 ! important;
    margin-right: 0 ! important;
  }

  .dialog-open {
    #my-main-container {
      display: none;
    }
  }
}

.dark-scroll {
  overflow-y: auto ! important;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #868584;
  }

}

.light-scroll {
  overflow-y: auto ! important;

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar:horizontal {
    height: 10px;
    width: 10px;
    background-color: lime;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $my-color-n5;
  }

}

.my-split-panels {
  &--horizontal,
  &--vertical {
    >.my-split-panel--panel1:not(.my-split-panel--spring)>div {
      height: 100%;
    }

    >.my-split-panel--panel2:not(.my-split-panel--spring)>div {
      height: 100%;
    }
  }

  &--vertical {
    >.my-split-panel--panel1.my-split-panel--spring>div {
      height: auto;
    }

    >.my-split-panel--panel2.my-split-panel--spring>div {
      height: auto;
    }
  }
}

@media screen {
  .my-sandvik-content-panel {
    height: 100%;
    border-radius: 10px;
    padding: 0 30px 30px 30px;

    @include media-xxs-only {
      padding: 0 15px 15px 15px;
    }

  }

  .my-sandvik-content-area {
    &--card {
      padding: 0;
      background-color: #fff;
      border-radius: 10px;

      .max-width {
        max-width: 1280px;
      }
    }
  }
}

.my-onboarding__body {
  P {
    max-width: 550px;
  }
}
