/* ------------------------------------------------------------ Breadcrumbs */
@import '../variables.scss';

.my-breadcrumbs {	
	margin: 25px 30px 20px 0;

	&__link-list {
		list-style: none;
		align-items: center;
		flex-wrap: wrap;
		display: flex;
		font-size: .875rem;  //Should use $my-font-size-sm, but can´t update that now since it will affect whole site
		-moz-column-gap: 1rem;
		column-gap: 1rem;
	}

	&__link {
		-moz-column-gap: 1rem;
		column-gap: 1rem;
		display: flex;
		align-items: center;

		&:hover {
			text-decoration-color: $my-color-g;
		}
	}

	&__separator {
		height: 20px;
	}
}

/* MOBILE */
@media only screen and (max-width: 768px) {
	#{ $mysandvik }breadcrumbs {
		margin: 25px 30px 20px 15px;
	}
}
