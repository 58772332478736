#{ $mysandvik }text {

    &-red,
    &--red,
    &--danger {
        color: $my-color-error;
    }

    &-green,
    &--green,
    &--success {
        color: $my-color-success;
    }

    &-orange,
    &--orange,
    &--warning {
        color: $my-color-vo;
    }

    &--alt-warning {
        color: $my-color-vo2;
    }

    &-black,
    &--black {
        color: $my-color-cb;
    }

    &-blue,
    &--blue {
        color: $my-color-eb;
    }

    &--strong {
      font-weight: 700;
    }

    &--gray {
      color: $my-color-n4;
    }
}
