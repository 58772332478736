// _tile_dashboard.scss

.dashboard-tile {
  color: #fff;
  height: 190px;
  & h3 {
    color: inherit;
    margin-bottom: 18px;
    font-weight: 400;
    & a {
      color: inherit;
    }
  }
}

.dashboard-tile.tile--blue,
.dashboard-tile.tile--orange {
  border: 0;
}

.dashboard-tile--active,
.dashboard-tile.active {
  overflow: initial;
  z-index: 0;
  &:after {
      background-color: inherit;
      display: block;
      content: "";
      transform: rotate(225deg);
      transform-origin: top left;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 50%;
      top: 203px;
      z-index: -1;
  }
}

.dashboard-tile--report {
	height: 210px;
	.dashboard-number-description {
		    white-space: normal;
	}
}

.dashboard-tile__contents {
    text-align: center;
    &:nth-of-type(2) {
  		background: rgba(255, 255, 255, 0.3) none repeat scroll 0% 0%;
  		display: inline-block;
  		height: 100%;
  		padding-top: 20px;
  		position: absolute;
  		right: 0;
  		top: 0px;
  		width: 32%;
    }
}

.dashboard-tile-cart .dashboard-tile__contents {
  &:nth-of-type(1) {
  	width: 77%;
      margin-left: -14px;
    & .dashboard-tile__content-1, .dashboard-tile__content-2 {
//      width: 66px;
      width: 85px;
    }
  }
  &:nth-of-type(2) {
    & .dashboard-tile__content-1, .dashboard-tile__content-2 {
      width: 100%;
    }
    & .dashboard-tile__content-2 {
      margin-top: 10px;
    }
  }
}

.dashboard-tile__content-1,
.dashboard-tile__content-2 {
  color: inherit;
  display: inline-block;
  font-size: 70px;
  width: 100px;
  text-align: center;
  vertical-align: top;
  &:hover {
    text-decoration: none;
        color: rgba(0,0,0,.6);
  }
}

.dashboard-tile__content-1--important {
  color: #005288;
}

.dashboard-number,
.dashboard-number-description {
	color: inherit;
	display: block;
}

.dashboard-number {
  font-size: 54px;
}

.dashboard-number--small {
  font-size: 40px;
}

.dashboard-number--x-small {
  font-size: 30px;
}

.dashboard-number-description--small{
    white-space: normal !important;
}

.dashboard-number-description {
  font-size: 13px;
  overflow: hidden;
  text-transform: uppercase;
//  text-overflow: ellipsis;
  white-space: nowrap;
//    word-break: break-word;
}

.dashboard-number--blue,
.dashboard-number-description--blue {
  color: $my-color-eb;
}
.dashboard-number--orange,
.dashboard-number-description--orange {
  color: $my-color-vo;
}

.dashboard-tile__action {
  text-align: center;
}

@media (min-width: 769px) and (max-width: 1268px) {
  .dashboard-tile-cart .dashboard-tile__contents {
    &:nth-of-type(1) {
      & .dashboard-tile__content-1, .dashboard-tile__content-2 {
        width: 100px;
      }
    }
  }
}

@media (max-width: 768px) {
  .dashboard-tile-cart .dashboard-tile__contents {
    &:nth-of-type(1) {
      & .dashboard-tile__content-1, .dashboard-tile__content-2 {
        width: 80px;
      }
    }
  }
}

@media (min-width: 769px) {
  #dashboard-toggle {
    display: none !important;
  }

  #main-dashboard {
    display: block !important;
  }
}
//@include responsive_max(768px) {

@media (max-width: 768px) {
//@include responsive-lte-small() {

  #dashboard-tile-cart,
  #dashboard-tile-approval,
  #dashboard-tile-template,
  #dashboard-tile-campaigns {
      margin-top: 1px !important;
  }

  .dashboard-tile {
    height: 52px;
    padding: 10px;
  }

  .dashboard-tile--active,
  .dashboard-tile.active::after {
    display: none;
  }
    .dashboard-tile h3 {
		  display: inline-block;
      font-size: 13px;
		  float: left;
      margin-bottom: 12px;
      padding: 8px;
    }

  .dashboard-tile__contents {
		display: inline-block;
		float: right;
    &:nth-of-type(2) {
      padding-top: 10px;
  		width: 160px;
    }
  }

  .dashboard-tile-cart .dashboard-tile__contents {
    &:nth-of-type(1) {
  		float: none;
  		position: absolute;
  		right: 160px;
      width: 160px;
      & .dashboard-tile__content-1, .dashboard-tile__content-2 {
        width: 66px;
      }
  	}
    &:nth-of-type(2) {
      & .dashboard-tile__content-1, .dashboard-tile__content-2 {
        width: 75px;
      }
      & .dashboard-tile__content-2 {
        margin-top: 0;
      }
    }
  }

  .dashboard-number,
  .dashboard-number--small {
    font-size: 20px;
  }

  .dashboard-number-description {
    font-size: 10px;
  }
}

/* iPhone 6 portrait */
@media (max-width: 357px) {
  .dashboard-tile__contents {
		float: right;
		position: static;
		width: auto;
	}

  .dashboard-tile-cart .dashboard-tile__contents {
    &:nth-of-type(1) {
  		float: right;
  		position: static;
  		width: auto;
  	}
    &:nth-of-type(2) {
  		display: none;
  	}
  }

  .dashboard-tile__content-1,
  .dashboard-tile__content-2,
  .dashboard-tile-cart .dashboard-tile__content-1,
  .dashboard-tile-cart .dashboard-tile__content-2 {
		width: 80px !important;
	}
}

//@include responsive_max(568px) { doesn't compile, mixin not found
@media screen and (max-width:568px) {
    .tile.dashboard-tile.tiles--size1 {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }
}

.test5 { display: block; }
