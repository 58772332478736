/**
This CSS resource incorporates links to font software which is the valuable copyrighted property of Monotype and/or its suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact Monotype with any questions regarding Web Fonts: http://www.fontshop.com
**/

@font-face {
	font-weight: 200;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans";
	src: url("#{$assets-fonts}SandvikSansText-Light.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansText-Light.woff") format("woff")
}

@font-face {
	font-weight: 200;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans";
	src: url("#{$assets-fonts}SandvikSansText-LightItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansText-LightItalic.woff") format("woff")
}

@font-face {
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans";
	src: url("#{$assets-fonts}SandvikSansText-Regular.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansText-Regular.woff") format("woff")
}

@font-face {
	font-weight: 400;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans";
	src: url("#{$assets-fonts}SandvikSansText-RegularItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansText-RegularItalic.woff") format("woff")
}

@font-face {
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans";
	src: url("#{$assets-fonts}SandvikSansText-Bold.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansText-Bold.woff") format("woff")
}

@font-face {
	font-weight: 700;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans";
	src: url("#{$assets-fonts}SandvikSansText-BoldItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansText-BoldItalic.woff") format("woff")
}

// Headline fonts

@font-face {
	font-weight: 200;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-Light.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-Light.woff") format("woff")
}

@font-face {
	font-weight: 200;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-LightItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-LightItalic.woff") format("woff")
}

@font-face {
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-Regular.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-Regular.woff") format("woff")
}

@font-face {
	font-weight: 400;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-RegularItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-RegularItalic.woff") format("woff")
}

@font-face {
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-Bold.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-Bold.woff") format("woff")
}

@font-face {
	font-weight: 700;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-BoldItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-BoldItalic.woff") format("woff")
}

@font-face {
	font-weight: 800;
	font-style: normal;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-ExtraBold.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-ExtraBold.woff") format("woff")
}

@font-face {
	font-weight: 800;
	font-style: italic;
	font-display: swap;
	font-family: "Sandvik Sans Headline";
	src: url("#{$assets-fonts}SandvikSansHeadline-ExtraBoldItalic.woff2") format("woff2"),
	url("#{$assets-fonts}SandvikSansHeadline-ExtraBoldItalic.woff") format("woff")
}