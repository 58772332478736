.plain-toolbar {
  padding: 2px;
  background-color: $my-color-n2;
  .material-icon {
    line-height: 1em;
    vertical-align: middle;
  }
  button {
    background-color: $my-color-n2;
    &:not(:disabled):hover {
      background-color: $my-color-n1;
      cursor: pointer;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}
