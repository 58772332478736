$mysandvik: '.my-';
$assets:   '/assets/';
$assets-fonts:  '/assets/fonts/';
$assets-images: '/assets/images/';

$my-font-family-text: "Sandvik Sans", Helvetica, Arial, sans-serif !default;
$my-font-family-headline: "Sandvik Sans Headline", Helvetica, Arial, sans-serif !default;

//----- My Sandvik colors ------

//Brand carbon black
$my-color-cb: #101010;

//Brand electric blue
$my-color-eb: #1441F5;

//Brand negative button red
$my-color-rd: red;
//Brand electric red shades
$my-color-rd1: #fd5858;

//Brand electric blue shades
$my-color-eb1: #C8DCFF;
$my-color-eb2: #327DFF;
$my-color-eb3: #003C7D;

//Brand vibrant orange
$my-color-vo: #F54B00;

//Brand vibrant orange shades
$my-color-vo1: #FFB991;
$my-color-vo2: #FF824B;
$my-color-vo3: #7D2300;

//Brand gold
$my-color-g: #A08C64;

//Brand gold shades
$my-color-g1: #EBE6DC;
$my-color-g2: #BEAF93;
$my-color-g3: #73644B;

//Brand neutral shades
$my-color-n1: #F8F8F8;
$my-color-n2: #E6E6E6;
$my-color-n3: #DADADA;
$my-color-n4: #9D9D9D;
$my-color-n5: #6F6F6F;
$my-color-n6: #575757;
$my-color-n7: #434343;
$my-color-n8: #242424;
$my-color-n9: #1B1B1B;

$my-color-white: #FFFFFF;

//System colors
$my-color-error: #EB0D0D;
$my-color-warning: #FFD74B;
$my-color-success: #7FCC5B;

//----- My Sandvik style variables ------
$my-color-border: $my-color-n3;
$my-color-border-dark: $my-color-n5;

$my-color-text: $my-color-cb;
$my-color-text-button: $my-color-white;
$my-color-text-button-secondary: $my-color-cb;
$my-color-text-link: $my-color-cb;

$my-color-background: $my-color-white;
$my-color-background-secondary: $my-color-n1;
$my-color-background-button: $my-color-eb;
$my-color-background-button-secondary: $my-color-n2;
$my-color-background-button-neagative: $my-color-error;
$my-color-background-success-button: $my-color-success;

$my-color-chart-1: $my-color-g;
$my-color-chart-2: $my-color-g2;
$my-color-chart-3: $my-color-n2;
$my-color-chart-4: $my-color-cb;

$my-font-size: 1rem;
$my-font-size-sm: .75rem; // Should be .875rem with new branding

$my-font-size-header: 1.625rem;
$my-font-size-header-m: 2.1875rem;
$my-font-size-header-l: 2.5rem;

$my-color-layout-workspace: #fff;
$my-color-layout-content: #fff;

$my-color-navigation-dark: $my-color-cb;
$my-color-navigation-dark-fg-dark: #757677; //TODO: Look into these colors
$my-color-navigation-fg: #F5F6F8; //TODO: Look into these colors
$my-color-navigation-fg-active: $my-color-vo;

$my-z-index-header: 300; //TODO: Replace below CSS variables, we should not mix

:root {
  // Z-index for the main content
  --z-index-content: 0;

  // Z-index for the header and sidebar
  --z-index-sidebar: 100;
  --z-index-header: 300;

  // We want popovers to be above sidebar but below header
  --z-index--popover: 200;

  // Elements floating on the screen
  --z-index--floaters: 300;

  // Z-index base for dialogs
  --z-index-dialog-base: 1000;

  --z-index-overlay: 50000;

  --my-color-background-secondary: #{$my-color-background-secondary};


  --my-color-chart-1: #{$my-color-chart-1};
  --my-color-chart-2: #{$my-color-chart-2};
  --my-color-chart-3: #{$my-color-chart-3};
  --my-color-chart-4: #{$my-color-chart-4};
  --my-color-success: #{$my-color-success};

  --my-color-eb1: #{$my-color-eb1};
  --my-color-vo1: #{$my-color-vo1};
  --my-color-vo2: #{$my-color-vo2};
  --my-color-eb2: #{$my-color-eb2};
  --my-color-n4: #{$my-color-n4};





}
