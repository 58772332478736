@import '../variables.scss';

#{ $mysandvik }card {
  border: 1px solid $my-color-border;
  margin-right: 30px;
  padding: 30px 30px 30px 30px;
  @include media-xxs-only {
    padding: 15px;
  }
  &--margin-bottom {
    margin-bottom: 30px;
  }
  &--full-height {
    height: 100%;
  }
  &--no-margin {
    margin: 0;
  }
  &--small-padding {
    padding: 15px;
  }
}

