//
// Include the global styles
// These are the same for all applications
//
@import 'scss/global-styles.scss';

// Should perhaps be in the component instead!
@import 'scss/components/ecommerce/tiles/tile';
@import 'scss/components/ecommerce/tiles/tile_dashboard';



html.ux-version--v1 {
  font-size: 13.4px;
}

.group-inner {
  // Remove if we should go with the "group" width
	max-width: 1340px;
}

[hidden] {display: none;}
