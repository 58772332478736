#{$mysandvik}fieldset {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: #ebebeb;
  padding: 15px 0;
  font-size: 1rem;
  font-weight: 200;
  padding-left: 15px;
  legend {
    text-transform: uppercase;
    padding-right: 10px;
    margin-left: -15px;
  }
}
