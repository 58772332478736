// When using a standard select and want a my sandvik look
#{$mysandvik}select {
  border: 1px solid $my-color-border;
  background-color: #fff;
  color: $my-color-text;
  font-size: 1rem;
  padding: 4px 30px 4px 8px;
  border-radius: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 50%;
  outline: none;
  &:disabled {
    border-color: #cdcdcd;
    background-color: #f8f8f8;
    color: #767676;
    cursor: not-allowed;
  }
  &.ng-invalid.ng-touched {
    border: 1px solid #f36a22;
    background-color: #fffbf9;
  }
  option {
    background-color: #fff;
    color: $my-color-text !important;
    &:checked, &[selected] {
      color: $my-color-text;
    }
    ::selection {
      color: $my-color-text;
    }
  }
}
