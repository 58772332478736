// _chart-dashboard.scss

/* Currently in use on fleet.php (not inc/fleet.php) */

.chart-dashboard {
  text-align: left;
}

/* Have to draw the chart at mobile otherwise it will get a weird size when
resizing. */
.chart-dashboard__chart {
  height: 75px;
  width: 75px;
  margin: 0 auto;
  display: block;
}

.chart-dashboard__button {
  float: right;
  display: inline-block;
}

.chart-dashboard__header {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 13px 0;
  display: inline-block;
}

@media (max-width: 768px) {
  /* Hide js chart */
  .chart-dashboard__chart {
    position:absolute;
    top: 100%;
    left:-99999px;
  }
}

  @media (min-width: 768px)  {
  .chart-dashboard {
    text-align: center;
  }
  .chart-dashboard__header {
    display: block;
  }
  .chart-dashboard__button {
    float: none;
  }

  .chart-dashboard__header,
  .chart-dashboard__button, {
    display: block;
  }
}
