@import '../variables.scss';

// Create mixins for the headers. These are re-used in the "body" block
@mixin mysandvik-h1 {
  color: $my-color-text;
  line-height: 40px;
  margin-bottom: 20px;
  font-size: 40px;
  font-family: $my-font-family-headline;
}

@mixin mysandvik-h2 {
  color: $my-color-text;
  line-height: 35px;
  margin-bottom: 20px;
  font-size: 35px;
  font-family: $my-font-family-headline;
}

@mixin mysandvik-h3 {
  color: $my-color-text;
  line-height: 26px;
  margin-bottom: 20px;
  font-size: 26px;
  font-family: $my-font-family-headline;
}


@mixin mysandvik-header-modifiers {
  &--orange {
    color: $my-color-text;
  }
  &--nocaps {
    text-transform: none;
  }
  &--nomargin, &--no-margin {
    margin:0;
  }
  &--nopadding {
    margin:0;
  }
}
