// _fleet-tile.scss

/* See fleet tile on index-site-manager */

.fleet-status {
  padding: 36px 0 0 0;
  list-style: none;
  margin: 0 0 18px 0;
}
.fleet-status__item:before {
  content: ' ';
  display: inline-block;
  width: 11px;
  height: 11px;
  margin-right: 7px;
}
.fleet-status__item--operational:before {
  background-color: $my-color-eb;
}
.fleet-status__item--idle:before {
  background-color: #F36A22;
}
.fleet-status__item--down:before {
  background-color: #DD0F0F;
}
.fleet-status__item--no-connection:before {
  background-color: #000;
}
.fleet-status__item--Standby:before {
  background-color: #FF6A00;
}
.fleet-status__item--Active:before {
  background-color: #0099FF;
}


@media (min-width: 769px) {
  .fleet__chart-container {
    width: 33%;
    padding: 0 18px;
  }

  .fleet-status {
    padding-top: 15%;
  }
}
