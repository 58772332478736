/*
 NOTE:
 Below styles also controls the styling of the third party toast ngx-toastr
 that is used as base for My Sandvik Toasts. Therefore below styles do not fully
 follow My Sandvik style guidelines.
*/

@import './../variables.scss';

$top-menu-offset: 75px;
$mobile-top-menu-offset: 102px;

@mixin toast-type($color) {
  border-left: 8px solid $color;

  .toast-title {
    color:$color;
    font-weight: bold;
  }
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px + $top-menu-offset;
  right: 12px;
}

@media all and (max-width: 1024px) {
  .toast-top-right {
    top: 12px + $mobile-top-menu-offset;
    right: 12px;
  }
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 24px 24px 24px 56px;
  width: 520px;
  border-radius: 6px 6px 6px 6px;
  background-position: top 24px left 15px;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: $my-color-text;

  &.toast-info {
    @include toast-type($my-color-eb);
  }

  &.toast-error {
    @include toast-type($my-color-error);
  }

  &.toast-success {
    @include toast-type($my-color-success);
  }

  &.toast-warning {
    @include toast-type($my-color-warning);
  }

  .toast-close-button {
    position: relative;
    right: -0.4em;
    top: -1.6em;
    float: right;
    font-size: 26px;
    color: $my-color-n4;
    font-family: sans-serif;
  }

  .toast-close-button:hover,
  .toast-close-button:focus {
    color: $my-color-n6;
    text-decoration: none;
    cursor: pointer;
  }

  button.toast-close-button {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
  }

  .toast-message {
    word-wrap: break-word;
    strong {
      font-weight: 700;
    }
    p:not(:last-of-type) {
      margin-bottom: 1rem;
    }

  }
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px $my-color-n6;
  opacity: 1;
}

// Temporarly added Google Material Symbols as encoded icons. We should switch when we have new icon package added to our solution.
.toast-info {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" %3E%3Cpath fill="%231441F5" d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/%3E%3C/svg%3E');
}

.toast-error {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" %3E%3Cpath fill="%23EB0D0D" d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/%3E%3C/svg%3E');
}

.toast-success {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" %3E%3Cpath fill="%237FCC5B" d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z"/%3E%3C/svg%3E');
}

.toast-warning {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" %3E%3Cpath fill="%23FFD74B" d="m40-120 440-760 440 760H40Zm440-120q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm-40-120h80v-200h-80v200Z"/%3E%3C/svg%3E');
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: $my-color-background;
  pointer-events: auto;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

// make toast fit screen width on smaller screens
@media all and (max-width: 768px) {
  .toast-container div.ngx-toastr {
    padding: 15px 15px 15px 55px;
    width: calc(100vw - 20px);
    background-position: top 20px left 15px;

    // toast button without toast title
    .toast-close-button:first-child {
      margin-top: 0.5em;
      right: 0;
    }

    // close button with toast title
    h3 + .toast-close-button {
      margin-top: 0.2em;
      right: 0;
    }

    .toast-message + .mt-2 {
      display: flex;
      flex-direction: column;
      gap: 6px;
      .my-button {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding-right: 6px;
        padding-left: 6px;
      }
    }

  }
}
