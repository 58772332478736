#{$mysandvik}button {
  $button: &;
  display: inline-flex;
  align-items: center;
	position: relative;
	min-height: 42px;
	width:auto;
	overflow:visible;
	padding: 4px 30px;
	outline: none;
  font-weight: 200;
	border-radius: 3px;
	background-color: transparent;
	color: $my-color-white;
	font-weight: 400;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	line-height: 30px;
	vertical-align: top;
	text-align: center;
	word-break: normal;
	cursor:pointer;
  font-size: 0.875rem;
  justify-content: center;
  background-color: $my-color-background-button;

  transition: border 0.162s ease, background 0.162s ease, opacity 0.162s ease;

  > mibp-sandvik-icon {
    margin: 0 8px;
  }

  > .material-icon {
    color: #fff;
    margin-top: -2px;
  }

  &--small {
    font-size: 0.75rem;
    padding: 2px 15px;
    min-height: 34px;
    line-height: 1.25rem;
  }

  &--xsmall {
    font-size: 0.74rem;
    padding: 2px 15px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-height: 20px;
    line-height: 1rem;
  }

  &:hover:not(:disabled) {
    background-color: $my-color-eb2;
    color: $my-color-white;
  }

  &:active:not(:disabled) {
    background-color: $my-color-eb3;
    color: $my-color-white;
  }

  &:disabled {
    background-color: $my-color-n3;
    color: $my-color-n4;
    cursor: default;
  }

  &--block {
    display: block;
    width: 100%;
  }

  &--fill {
    color: #fff;
    background-color: $my-color-eb;
    .material-icon {
      color: #fff;
    }
    &:disabled {
      border-color: #d1cbcb;
      color: #ada7a7;
      background-color: #e7e7e7;
    }
    &:hover:not(:disabled) {
      background-color: $my-color-eb2;
      color: #fff;
    }
  }

  &--danger {
    color: #fff;
    background-color: $my-color-rd;
    .material-icon {
      color: #fff;
    }
    &:disabled {
      border-color: #d1cbcb;
      color: #ada7a7;
      background-color: #e7e7e7;
    }
    &:hover:not(:disabled) {
      background-color: darken($my-color-rd, 5);
      color: #fff;
    }
  }

  &--secondary {
    background-color: $my-color-background-button-secondary;
	  color: $my-color-text-button-secondary;
    &:not(:disabled) .material-icon {
      color: $my-color-cb;
      margin-top: -2px;
    }
    &:disabled .material-icon {
      color: $my-color-n4;
      margin-top: -2px;
    }


    &:hover:not(:disabled) {
      background-color: darken($my-color-background-button-secondary, 15%);
      color: $my-color-text-button-secondary;
    }
  }

}

#{$mysandvik}toolbar {
  display: flex;
  justify-content: space-between;
  $block: &;
  margin: 15px 0;

  &--no-margin {
    margin:0;
  }

  &--blue {
    background: #F2FAFF;
    padding: 10px;
    border-bottom: 1px solid $my-color-border;
  }

  &--gray {
    background: #fefefe;
    padding: 10px;
    border-bottom: 1px solid $my-color-border;
  }

  @include media-xs-and-smaller {
    display: block;
    #{$mysandvik}button {
      display: block;
    }
  }

  &__left, &__right, &__center {
    display: flex;
      // Give all children some space by default
    & > * + * {
      margin-left: 15px;
    }
    @include media-xs-and-smaller {
      display: block;
      & > * + * {
        margin-left: 0;
      }
      #{$mysandvik}button {
        display: block;
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  &__right {
    justify-content: flex-end;
  }

  &__center {
    justify-content: center;
    width: 100%;
  }

  &__right:only-child {
    width: 100%;
  }

  &__group {
    display: flex;
    > #{$mysandvik}button + #{$mysandvik}button {
      border-left: 0;
    }
  }
}
