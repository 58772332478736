
@media print {

  .hide--print {
    display: none;
  }

  .printcolumns {
    .columns {
      margin-top: 0;
    }
  }

}


