
#{$mysandvik}badge {

  color: #fff !important;
  // width: 22px;
  // height: 22px;

  min-width: 22px;;
  padding: 0 6px;
   line-height: 1.8em;

  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  text-shadow: 1px 1px rgb(0 0 0 / 20%);
  line-height: 22px;
  position: relative;
  display: inline-block;
  margin-left: -11px;
  margin-top: -34px;

  background-color: #686a71;

  &--blue {
    background: $my-color-eb;
  }

  &--orange {
    background: $my-color-vo;
  }

  &--error,
  &--danger {
    background: $my-color-error;
  }

  &--inline {
    margin-left: 7px;
    margin-top: 0;
  }

  &--longtext {
    border-radius: 5px;
  }

  &--limit-width {
    max-width: 50px;
    text-overflow: ellipsis;
    position: relative;
    overflow: inherit;
    vertical-align: bottom;
  }

  &--bouncy-highlight {
    animation: zoom-in-zoom-out 1s ease 2;
  }

}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}
