/* ------------------------------------------------------------ Header */

/*

 My Sandvik HEADER block (BEM style)

 H-tag will decide size
 Blue by default

 --orange modifier will make it orange
 --nocaps modifier will remove uppercase transformation

 <h1 class="my-header"></h1>
 <h1 class="my-header"></h1>
 <h1 class="my-header my-header--nocaps"></h1>

*/



h1 {
  &#{$mysandvik}header {
    @include mysandvik-h1();
    mibp-resource-string {
      font-size: inherit;
      color: inherit;
    }
  }
}

h2 {
  &#{$mysandvik}header {
    @include mysandvik-h2();
    mibp-resource-string {
      font-size: inherit;
      color: inherit;
    }
  }
}

h3 {
  &#{$mysandvik}header {
    @include mysandvik-h3();
    mibp-resource-string {
      font-size: inherit;
      color: inherit;
    }
  }
}

/** Modifiers **/
h1, h2, h3, h4, h5, h6 {
  &#{$mysandvik}header,
  &#{$mysandvik}header mibp-resource-string {
    @include mysandvik-header-modifiers;
  }
}

.page--light .header {
	border-bottom-color: #fff;
	background: #fff;
}

.header-micro {
	padding-bottom:15px;
	text-align:right;
}

.header-micro-bar {
	display: flex;
}
.header-logo-wrapper {
	flex: 1;
}

.header.header-borderless {
	border-bottom:0;
}

.header-with-headerbar[class] {
	padding-top: 0;
}


/* Logo */

.logo {
	position: absolute;
	top: 50%;
	left: 80px;
	margin-top: -53px;
	border-bottom: none;
}

@media only screen and (min-width:721px) {
	.header-micro .logo {
		position:static;
		float:left;
		margin:0;
	}
}

.logo img {
	display: block;
	max-width: none;
	width:260px;
	height:95px;
}

@media only screen and (min-width:721px) {
	.header-micro .logo img {
		width:137px;
		height:50px;
  }
}

/* NIC header */

.header-nic img {
	float: left;
	height: 50px;
}

.header-nic p {
	float: right;
	margin-top: 12px;
}

.header-nic a {
	border: none;
	text-transform: uppercase;
}

.header-nic a:after {
	margin-left: 10px;
	content: url(#{$assets-images}/arrow-right-blue-20x15.svg);
}

/* Main navigation */

.nav-main {
	float: left;
	margin-left: 31.25%; /* 400/1280 */
	line-height: 25px;
}

.nav-main ul {
	margin-left: -20px;
	list-style:none;
}

.nav-main li {
	margin:0;
}

.nav-main li {
	display: block;
	position: relative;
	padding-left: 20px;
	border: none;
	text-transform: uppercase;
}

.page--light .nav-main li a {
	color: #000;
}

.nav-main[class] li a:hover,
.nav-main[class] li a:focus,
.nav-main[class] li a:active {
	color: rgb(255, 106, 0);
}

.nav-main li.selected > a {
	background: url(#{$assets-images}arrow-tail-right-orange-13x11.svg) 0 50% no-repeat;
	color: rgb(255, 106, 0);
}

.no-svg .nav-main li.selected > a {
	background-image: url(#{$assets-images}arrow-tail-right-orange-13x11.png);
}


.lt-ie8 .nav-main li.selected > a {
	display:inline;
	zoom:1;
}

.nav-main li[data-has-children] > ul {
	display: none;
}

/* Horizonal main navigation (micro site header) */

.header-micro-nav {
	clear:left;
	padding-top:20px;
	text-align:left;
	text-transform:uppercase;
}

.header-micro-nav li {
	display:inline-block;
	margin-top:5px;
	margin-right:20px;
}

.header-micro-nav[class] a {
	border:none;
	font-size:16px;
	line-height:24px;
}

.page--light .header-micro-nav[class] a {
	color: #000;
}

.page--light .header-micro-nav[class] .selected a,
.page--light .header-micro-nav[class] a:hover,
.page--light .header-micro-nav[class] a:focus {
	color:#ff6a00;
}

.header-cart-count {
	position:absolute;
	top:0;
	left:13px;
	min-width:16px;
	padding:0 2px;
	border:1px solid #ff6a00;
	background:#000;
	color:#ff6a00;
	font-weight:400;
	font-size:10px;
	line-height:12px;
	text-align:center;
}

@media only screen and (max-width: 900px) {
	.header-cart-account {
		margin-bottom:15px;
	}
}

/* Supplemental navigation */

.language {
	float: right;
	text-align: right;
}

@media only screen and (min-width:901px) {
	.header-micro .language {
		margin-left:25px;
	}
}

.language p {
	color: #767676;
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	text-transform: uppercase;
}

.language p strong {
	color: #3d3d3d;
	font-weight: 700;
}

.language a {
	position: relative;
}

.language .change-language-link {
	position:relative;
	margin-right: 32px;
}

.language .change-language-link button {
	line-height:21px;
}

.language a img,
.language .change-language-link img {
	position: absolute;
	top: 0;
	right: -32px;
}

.language .change-language-link:before {
	position:absolute;
	top:3px;
	right:-32px;
	width:22px;
	height:16px;
	border:1px solid #ebebeb;
	content:"";
}

.language .change-language-link--no-icon {
	margin-right: 0;
}

.language .change-language-link--no-icon:before {
	content: none;
}

html:not(.kanthal) .language .change-language-link[data-popover-trigger]:before {
	top: 1px;
}

.language .change-language-parent-site-link {
	border-bottom: none;
}

.language .change-language-parent-site-link span {
	border-bottom:1px solid;
	border-bottom-color:rgb(0, 153, 255);
	border-bottom-color:rgba(0, 153, 255, 0.3);
	transition:border 0.2s ease;
}

.language .change-language-parent-site-link:hover span,
.language .change-language-parent-site-link:focus span {
	border-bottom-color:rgb(0, 95, 159);
}

/* Search form */

.search {
	position: relative;
	float: left;
}

.header .search {
	position: absolute;
	right: 80px;
	bottom: 0;
	float: right;
	clear: right;
}

@media only screen and (min-width:721px) {
	.header-micro .search {
		display:inline-block;
		position:relative;
		right:0;
		float:none;
		vertical-align:top;
	}
}

.lt-ie9 .header .search {
	right:40px;
}

.js .search[aria-expanded="false"] {
	display: none;
}

.search .search-field {
	min-height: 50px;
	width: 460px;
	padding: 9px 10px 9px 65px;
	border-color: #d3d3d3;
	background: #fff url(#{$assets-images}search-orange-31x31.svg) 17px 50% no-repeat;
}

.page--light .search .search-field {
	border-color:#d3d3d3;
	background-color: #fff;
	color: #000;
}

.page--light .search .search-field:hover,
.page--light .search .search-field:focus {
	border-color: #aaa;
	background-color: #fff;
}

.no-svg .search .search-field {
	background-image: url(#{$assets-images}search-orange-31x31.png);
}

@media only screen and (min-width:901px) {
	.header-micro .search .search-field {
		min-height:48px;
	}
}

@media only screen and (min-width:721px) {
	.header-micro .search .search-field {
		width:380px;
	}
}

.search.has-text .search-field {
	padding-right: 100px;
}

.ie8 .search .search-field {
	min-height: 32px;
}

.lt-ie8 .search .search-field {
	width: 335px;
	line-height: 52px;
}

.search .search-field::-webkit-input-placeholder {
	color: #ababab;
	font-weight: bold;
	font-style: normal;
	 -webkit-font-smoothing: antialiased;
	font-size: 14px;
	text-transform: uppercase;
}

.search .search-field:-moz-placeholder,
.search .search-field::-moz-placeholder {
	color: #ababab;
	font-weight: bold;
	font-style: normal;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
	text-transform: uppercase;
}

.search .search-field:-ms-input-placeholder {
	color: #ababab;
	font-weight: bold;
	font-style: normal;
	font-size: 14px;
	text-transform: uppercase;
}

.search .search-field.autocomplete-active:hover,
.search .search-field.autocomplete-active:focus {
	border-color: #d3d3d3;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.search .search-submit {
	position: absolute;
	top: 0;
	right: 0;
	min-height: 52px;
	padding: 9px 20px 11px;
	border: none;
	font-size: 14px;
	-webkit-transition: color 0.162s ease, opacity 0.162s ease;
	        transition: color 0.162s ease, opacity 0.162s ease;
}

.no-touch .search .search-submit,
.no-js .search .search-submit {
	opacity:0;
}

.ie9 .search .search-submit {
	opacity:1;
}

.no-touch .search .search-submit:hover,
.no-touch .search .search-submit:focus,
.no-js .search .search-submit:hover,
.no-js .search .search-submit:focus,
.search.has-text .search-submit,
.no-js .search .search-field:focus + .search-submit,
.no-js .search .search-field:focus + .autocomplete-placeholder + .search-submit {
	border-color: transparent;
	opacity: 1;
}

@media only screen and (max-width: 1260px) {
	// .header {
	// 	padding: 20px 0 25px;
	// }

	.header-micro {
		padding-bottom: 15px;
	}

	.logo {
		margin-top: -40px;
	}

	.logo img {
		width: 218px;
		height:80px;
	}

	.search .search-field {
		width: 420px;
	}
}

@media only screen and (min-width:721px) and (max-width: 1260px) {
	.header-micro .search .search-field {
		width: 250px;
	}
}

@media only screen and (max-width: 1100px) {
	.search .search-field {
		width: 350px;
	}
}

@media only screen and (max-width: 1050px) {
	.header-micro {
		padding-bottom: 15px;
	}

	.logo {
		left: 20px;
	}

	.nav-main a {
		line-height: 20px;
	}

	.header .search {
		right: 20px;
	}

	.search .search-field {
		min-height: 45px;
		padding-left: 55px;
		background-image: url(#{$assets-images}search-orange-24x24.svg);
		background-position: 15px 11px;
	}

	.search .search-field::-webkit-input-placeholder {
		font-size: 13px;
	}

	.search .search-field:-moz-placeholder,
	.search .search-field::-moz-placeholder {
		font-size: 13px;
	}

	.search .search-field:-ms-input-placeholder {
		font-size: 13px;
	}

	.no-svg .search .search-field {
		background-image: url(#{$assets-images}search-orange-24x24.png);
	}

	.search .search-submit {
		min-height: 47px;
		font-size: 13px;
		line-height: 25px;
	}
}

@media only screen and (min-width:721px) and (max-width: 1050px) {
	.header-micro .logo img {
		width:123px;
		height:45px;
	}

	.header-micro .search {
		right: 0;
	}
}

@media only screen and (max-width: 1024px) {
	.header-app {
		display: none;
	}
}

@media only screen and (max-width: 900px) {

	.menu-active .header {
		padding-bottom: 0;
	}

	.header.header-with-headerbar {
		min-height: 138px;
	}

	.header.header-with-headerbar-extra-height {
		min-height: 172px;
	}

	.menu-active .header.header-with-headerbar-extra-height {
		min-height: 155px;
		margin-bottom: 37px;
	}

	.sticky-menu .header {
		position: fixed;
		top: 0;
		width: 100%;
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
		background: #fff;
		-webkit-transform: translate(0, -150px);
				transform: translate(0, -150px);
	}

	.sticky-menu-animation-end .header {
		-webkit-transform: translate(0, 0);
				transform: translate(0, 0);
		-webkit-transition: -webkit-transform 0.3s ease-out;
				transition:         transform 0.3s ease-out;
	}

	.sticky-menu-hide .header {
		-webkit-transform: translate(0, -150px);
				transform: translate(0, -150px);
		-webkit-transition: -webkit-transform 0.3s ease-out;
				transition:         transform 0.3s ease-out;
	}

	.overthrow-enabled.touch .menu-active .header {
		position:fixed;
		top:0;
		right: 0;
		left:0;
	}

	.page--light.overthrow-enabled.touch .menu-active .header {
		background: #fff;
	}

	.logo {
		top: 0;
		margin-top: 0;
	}

	.header > div {
		-webkit-transition: padding-top 0.12s ease;
		        transition: padding-top 0.12s ease;
	}

	.no-js .header > div {
		padding-top: 70px;
	}

	.no-js .logo-and-nav-main {
		display: block;
		float: none;
	}

	.header > div.search-is-open {
		padding-top: 70px;
	}

	.search .search-field {
		width: 100%;
	}

	.logo img {
		width: 120px;
		height:44px;
	}

	.header .search {
		top: 0;
		right: 20px;
		bottom: auto;
		float: none;
	}

	.no-js .header .search {
		max-width:360px;
		margin-left: 160px;
	}

	.js .search {
		position: absolute;
		right: 160px;
		left: auto;
		width: 360px;
	}

	/* Mid-to-small screen navigation */

	.nav-main {
		display: block;
		float: none;
		margin: 0 15px;
		text-align: left;
	}

	.js .nav-main,
	.js .language {
		display:none;
	}

	.nav-main .hidden-in-mobile {
		display: none;
	}

	.nav-main a {
		padding: 10px 0;
		line-height: 28px;
	}

	.no-js .nav-main {
		margin: 15px 0;
	}

	.nav-main ul {
		margin-left: 0;
	}

	.nav-main li {
		position: relative;
		border-top: 1px solid;
	}

	.page--light .nav-main li {
		border-top-color: #d3d3d3;
	}

	.nav-main > ul > li {
		border-right: 1px solid;
		border-left: 1px solid;
	}

	.page--light .nav-main > ul > li {
		border-right-color: #d3d3d3;
		border-left-color: #d3d3d3;
	}

	.nav-main > ul > li[data-expanded] {
		border: 1px solid rgb(255, 106, 0);
	}

	.nav-main > ul > li[data-expanded] + li {
		border-top: none;
	}

	.nav-main > ul > li[data-expanded] > a {
		border-bottom: 1px solid;
		font-weight: 400;
	}

	.page--light .nav-main > ul > li[data-expanded] > a {
		border-bottom-color: #d3d3d3;
	}

	.nav-main > ul > li > ul > li:first-child {
		border: none;
	}

	.nav-main li li a {
		padding-right:25px;
		text-transform: none;
	}

	.nav-main li li a {
		padding-left: 50px;
	}

	.nav-main li li li a {
		padding-left: 75px;
	}

	.nav-main li li li li a {
		padding-left: 100px;
	}

	.nav-main li li li li li a {
		padding-left: 125px;
	}

	.nav-main li li li li li li a {
		padding-left: 150px;
	}

	.nav-main li li li li li li li a {
		padding-left: 175px;
	}

	.nav-main > ul > li.selected a,
	.no-svg .nav-main > ul > li.selected a {
		background: none;
	}

	.nav-main li[data-selected] > a {
		color: rgb(255, 106, 0);
	}

	.nav-main li[data-has-children] {
		cursor: pointer;
	}

	.js .nav-main li[data-has-children] > a {
		margin-right: 48px;
		border-right: 1px solid #d3d3d3;
	}

	.js.page--light .nav-main li[data-has-children] > a {
		border-right-color: #d3d3d3;
	}

	.js .nav-main li[data-has-children]:after {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 48px;
		height: 49px;
		background: url(#{$assets-images}plus-orange-25x25.svg) no-repeat 50% 50%;
		content: "";
	}

	.js.no-svg .nav-main li[data-has-children]:after {
		background-image: url(#{$assets-images}plus-orange-25x25.png);
	}

	.js.page--light .nav-main > ul > li[data-expanded] > a {
		border-right-color: #fff;
	}

	.js .nav-main li[data-expanded]:after {
		background: url(#{$assets-images}minus-orange-25x25.svg) no-repeat 50% 50%;
	}

	.js.no-svg .nav-main li[data-expanded]:after {
		background-image: url(#{$assets-images}minus-orange-25x25.png);
	}

	.js .nav-main > ul > li[data-expanded]:after {
		background: rgb(255, 106, 0) url(#{$assets-images}minus-white-25x25.svg) no-repeat 50% 50%;
	}

	.js.no-svg .nav-main > ul > li[data-expanded]:after {
		background-image: url(#{$assets-images}minus-white-25x25.png);
	}

	.nav-main li[data-expanded] > ul {
		display: block;
	}

	.overthrow-enabled.touch .menu-active {
		overflow: hidden;
	}

	.js .menu-active .header {
		border-bottom-color: transparent;
	}
}

@media only screen and (max-width: 720px) {

	.header-nic {
		min-height: 0;
	}

	.header > .group-inner {
		padding-top:44px;
	}

	.header-nic .group-inner {
		padding-top: 0;
	}

	.js .search {
		position: relative;
		right: 20px;
		left: 0;
		float: none;
		width: auto;
		margin: 20px 0 0;
	}

	.js .menu-wrapper[aria-expanded="true"] {
		top: 130px;
	}

	.js .menu-wrapper-cart[aria-expanded="true"] {
		top: 160px;
	}

	.js .menu-wrapper-cart.menu-wrapper--headerbar-push {
		top: 177px;
	}
	.js .menu-wrapper-cart.menu-wrapper--headerbar-large-push {
		top: 210px;
	}

	.menu-wrapper .language {
		text-align: center;
	}

	.header-nic img {
		height: 35px;
	}

	.header-nic p {
		margin-top: 6px;
	}
}

@media only screen and (max-width: 500px) {
	// .header {
	// 	min-height:85px;
	// 	padding: 15px 0;
	// }

	.header-cart-account {
		margin-top: -15px;
	}

	.header-cart-account li a,
	.header-cart-account li button {
		font-size:14px;
	}

	.header-nic {
		min-height: 0;
	}

	.header > .group-inner {
		padding-top:32px;
	}

	.header-nic .group-inner {
		padding-top: 0;
	}

	.header > div.search-is-open {
		padding-top: 55px;
	}

	.logo {
		left: 15px;
	}

	.logo img {
		width:88px;
		height:32px;
	}

	.header .button.search-toggler,
	.header .button.menu-toggler {
		min-height: 32px;
		padding: 5px 23px;
		line-height: 20px;
	}

	.header .button.menu-toggler {
		top:15px;
		right:15px;
		margin-left: 4px;
		padding: 5px 18px 5px 45px;
		background-position: 18px 50%;
	}

	.header-with-cart .button.menu-toggler {
		top:50px;
	}

	.header-with-headerbar .button.menu-toggler {
		top: 72px;
	}

	.header-with-headerbar-extra-height .button.menu-toggler {
		top: 105px;
	}

	.header .search {
		right: 15px;
		left: 0;
	}

	.no-js .header .search {
		top:40px;
		max-width:100%;
		margin-left:15px;
	}

	.search-toggler[aria-pressed="true"] {
		background-image: url(#{$assets-images}close-blue-20x20.svg);
	}

	.no-svg .search-toggler[aria-pressed="true"] {
		background-image: url(#{$assets-images}close-blue-20x20.png);
	}

	.js .search {
		margin: 15px 0 0;
	}

	.search .search-field {
		min-height: 37px;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 45px;
		background-position: 10px 5px;
	}

	.search .search-submit {
		min-height: 37px;
		line-height: 15px;
	}

	.js .menu-wrapper {
		padding-right: 15px;
		padding-left: 15px;
	}

	.js .menu-wrapper[aria-expanded="true"] {
		top: 100px;
	}

	.js .menu-wrapper-cart[aria-expanded="true"] {
		top: 130px;
	}

	.js .menu-wrapper-cart.menu-wrapper--headerbar-push {
		top: 152px;
	}
	.js .menu-wrapper-cart.menu-wrapper--headerbar-large-push {
		top: 185px;
	}
}

@media print {
	.header {
		padding: 0;
		border: none;
	}

	.logo,
	.logo img {
		position: static;
		max-width: 109px;
	}

	.search,
	.nav-main,
	.language,
	.header .button.menu-toggler {
		display: none;
	}
}
