@import '../variables.scss';
@import '../mixins/all';

.tabbed-module {
  margin-top: 0px;
   .tab-navigation {
    position: relative;

    .tabs {
      $block: &;
      display: inline-flex;
      gap: 30px;
      position: relative;
      border-bottom: 1px solid $my-color-border;

      // Quirk to get <ul> border to be full width for desktop
      &--full-width-ul {
        width: 100%;
      } 
      // End of quirk

      li {
        position: relative;
        a {
          text-decoration: none;
          display: flex;
          line-height: 1.2em;
          text-wrap: nowrap;
          padding: 0 2px 16px 0;
          position: relative;
        }
        &.tab-selected {
          a {
            font-weight: 700;
            border-color: $my-color-vo;
            &:after {
              content: '';
              position: absolute;
              width: 100%;
              bottom: -1px;
              background-color: $my-color-vo;
              height: 2px;
            }
          }
        }
      }
    }
  }
}


  .scroll-indicator {
    position: absolute;
    height: 100%;
    width: 8px;
    z-index: 1000000;
    top: 3px;
    opacity: 0;
    transition: opacity 0.5s;
    width: 60px;
    pointer-events: none;
    border-radius: 40px;
    &--right {
      margin-left: -10px;
      background: rgb(0, 0, 0);
      background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(83, 83, 83, 0.14931722689075626) 180%);
    }
    &--left {
      margin-left: -45px;
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(83, 83, 83, 0.14931722689075626) 180%);
    }
    &--visible {
      opacity: 1;
    }
  }



.tabbed-module .tab-content {
 	padding-top: 30px;
  &.hidden-tab {
    display: none;
  }
 }

 .has-content-to-the-right {
    position: absolute;
    right: 0;
    height: 100%;
    width: 8px;
    /* background-color: lime; */
    z-index: 1000000;
    top: 0;
    background: rgb(0, 0, 0);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(83, 83, 83, 0.14931722689075626) 180%);
 }
