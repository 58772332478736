//
// To reset table css to a non 1.0 style
//

@mixin mysandvik-reset-table {
  border: none;
  margin:0;
  padding:0;
  tr, th, td, tbody, thead {
    margin: 0;
    padding: 0;
    border: none;
  }
}
