
/* MOBILE */
@media only screen and (max-width: 768px) {

    .hide--mobile {
        display: none;
    }

}

  /* TABLET */
  @media only screen and (min-width: 769px) and (max-width: 1023px) {
    .hide--tablet {
      display: none;
    }
  }

  /* DESKTOP (DEFAULT) */
  @media only screen and (min-width: 1024px) {
  /* STYLES HERE */
  }

@mixin media-tablet {
  @media only screen and (min-width: 769px) and (max-width: 1023px) {
   @content;
  }
}

@mixin media-mobile {
  @media only screen and (max-width: 768px) {
   @content;
  }
}

@mixin media-tablet-and-less {
  @media only screen and (max-width: 1023px) {
   @content;
  }
}

@mixin media-tablet-and-larger {
  @media only screen and (min-width: 769px) {
   @content;
  }
}




$width-xxs: 780px;
$width-xs: 1024px;
$width-s: 1280px;
$width-m: 1536px;
$width-l: 1920px;

@mixin media-xxs-only() {
  @media only screen and (max-width: $width-xxs) {
   @content;
  }
}

@mixin media-xs-only {
  @media only screen and (max-width: $width-xs) and (min-width: $width-xxs + 1) {
   @content;
  }
}


@mixin media-s-only {
  @media only screen and (max-width: $width-s) and (min-width: $width-xs + 1) {
   @content;
  }
}

@mixin media-m-only {
  @media only screen and (max-width: $width-m) and (min-width: $width-s + 1) {
   @content;
  }
}

@mixin media-l-only {
  @media only screen and (max-width: $width-l) and (min-width: $width-m + 1) {
   @content;
  }
}

@mixin media-xl-only {
  @media only screen and (min-width: $width-l + 1) {
   @content;
  }
}


@mixin media-not-xxs {
  // Styles for all except xxs
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}


@mixin media-not-xs {
  // Styles for all except xs
  @include media-xxs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-xs-and-larger {
  // Visible for xs and larger
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-xs-and-smaller {
   // Styles for xs and smaller
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
}


@mixin media-not-s {
  // Styles for all except s
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-s-and-larger {
   // Styles for s and larger
   @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }

}

@mixin media-s-and-smaller {
  // Styles for s and smaller
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
}


@mixin media-not-m {
  // Styles for all except m
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-m-and-larger {
  // Styles for m and larger
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-m-and-smaller {
  // Styles for m and smaller
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
}

@mixin media-not-l {
  // Styles for all except l
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-l-and-larger {
  // Style applies to l and larger
  @include media-l-only() {
    @content;
  }
  @include media-xl-only() {
    @content;
  }
}

@mixin media-l-and-smaller {
  // Styles for l and smaller
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
}

@mixin media-not-xl {
  // Styles for all except xl
  @include media-xxs-only() {
    @content;
  }
  @include media-xs-only() {
    @content;
  }
  @include media-s-only() {
    @content;
  }
  @include media-m-only() {
    @content;
  }
  @include media-l-only() {
    @content;
  }
}

@mixin media-xl-and-larger {
  // Styles for xl and larger
  @include media-xl-only() {
    @content;
  }
}
