#{$mysandvik}data-table {
  position: relative;
  margin-bottom: 1em;
  border-top: 1px solid #000;
  font-size: 1.35em;
  margin-top: 2em;
  caption {
    font-weight: bold;
  }


  caption {
    text-align: left;
  }
  th, td {
    border-bottom: 1px solid #000;
    padding: 8px 6px;
  }

  tbody tr>:first-child {
    border-right: 1px solid #d3d3d3;
  }

}


@media print {
  #{$mysandvik}data-table {
    font-size: 50px;
  }
}
