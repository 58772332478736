
@use 'sass:math';

.my-dashboard-grid {
  $block: &;
  display: flex;
  // flex-wrap: wrap;

  &--4-columns {
    #{ $block  }__card {
      width: #{ math.div(100, 4) + '%' };
      @include media-s-and-smaller {
        width: 50%;

        &:nth-child(2) {
          #{ $block  }__card-content {
            margin-right: 0;
          }
        }
      }
      @include media-xxs-only {
        width: 100%;
        #{ $block  }__card-content {
          margin-right: 0;
        }
      }
    }
  }

  &--3-columns {
    #{ $block  }__card {
      width: #{ math.div(100, 3) + '%' };
      @include media-xxs-only {
        width: 100%;
      }
    }
  }

  &--2-columns {
    #{ $block  }__card {
      width: #{ math.div(100, 2) + '%' };
      @include media-xxs-only {
        width: 100%;
      }
    }
  }

  &--1-columns {
    #{ $block  }__card {
      width: 100%;
      #{ $block  }__card-content {
        margin-right: 0;
      }
    }
  }

  // &__column {
  //   &--single {
  //     width: 25%;
  //   }
  //   &--double {
  //     width: 100%;
  //     @include media-xxs-only {
  //       width: calc(100%);
  //     }
  //     @include media-xs-only {
  //       width: calc(100% - 40px);
  //     }
  //     @include media-s-only {
  //       width: calc(100% - 15px);
  //     }
  //     @include media-m-only {
  //       width: calc(100% - 15px);
  //     }
  //     @include media-l-only {
  //       width: calc(100%);
  //       display: flex;
  //     }
  //   }
  // }

  &__card {
    background-color: #fff;


    &-content {
      border: 1px solid #d3d3d3;
      margin-right: 30px;
      padding: 30px 30px 30px 30px;
      height: 100%;



      @include media-xxs-only {
        margin-right: 0;
      }

    }

    //margin-right: 30px;
    margin-bottom: 30px;

    &:last-child:not(:only-child) {
      #{$block}__card-content {
        margin-right: 0;
      }
    }


    h2 {
      text-decoration: uppercase;
      font-weight: bold;
      margin-bottom: 20px;
      a {
        color: #000;
        border: none;
        text-decoration: none;
        &:hover {
          color: $my-color-vo;
        }
      }
    }

    h1 {
      text-decoration: uppercase;
      font-weight: bold;
      line-height: 26px;
      font-size: 26px;
      font-weight: 200;
      margin-bottom: 20px;
      color: $my-color-vo;
      a {
        color: $my-color-vo;
        border: none;
        text-decoration: none;
        &:hover {
          color: $my-color-vo;
        }
      }
    }

    @include media-xs-only {
      // justify-content: space-evenly;
      margin-right: 0;
    }

    // @include media-m-only {
    //   justify-content: space-evenly;
    //   margin-right: 0;
    // }

    // &--single {
    //   width: 25%;
    //   @include media-m-and-smaller {
    //     width: auto;
    //     width: calc(50% - 30px);
    //   }
    //   @include media-l-only {
    //     width: calc(25% - 30px);
    //   }
    //   @include media-xxs-only {
    //     width: calc(100%);
    //     margin-right: 0;
    //   }

    // }
    // &--double {
    //   width: 50%;
    //   @include media-m-and-smaller {
    //     width: calc(100% - 15px);
    //    // width: 45%;
    //   }
    //   @include media-l-only {
    //     width: calc(50% - 30px);
    //   }
    //   @include media-xxs-only {
    //     margin-right: 0;
    //     width: 100%;
    //   }
    // }

  }






  @include media-xs-only {
    // justify-content: space-evenly;
  }

  @media only screen and (max-width: 1919px) {
   flex-wrap: wrap;
  }

    &__column--double > .my-dashboard-grid__card--double {
      width: calc(100%);
    }
}

.my-dashboard-grid > .my-dashboard-grid__column--double:last-of-type {
  width: 100%;
  margin-right: 0;
  > .my-dashboard-grid__card--double {
    width: 100%;
  }
}


.my-dashboard-grid {
  $block: &self;
  & > &__column {
    .my-dashboard-grid__card:last-of-type {
      margin-right: 30px;
    }
  }
}
