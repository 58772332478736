
@media screen {

  .bg--navigation {
    background-color: #000;
  }

  .fg-dark--navigation-dark {
    color: $my-color-navigation-dark-fg-dark
  }

  .fg--navigation {
    color: $my-color-navigation-fg;
  }

  .bg--layout-workspace {
    background-color: $my-color-layout-workspace;
  }

  .bg--layout-content {
    background-color: $my-color-layout-content;
  }

}
