.my-discreet-error {
 p {
  color: #555;
  font-style: italic;
  margin-bottom: 20px;
  a {
    font-style: normal;
  }
 }
}
