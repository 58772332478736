.my-mt {
  margin-top: 30px;
}

.my-mt-small {
  margin-top: 15px;
}

.my-mb {
  margin-bottom: 30px;
}

.my-mb-small {
  margin-bottom: 15px;
}

.my-ml {
  margin-left: 30px;
}

.my-ml-small {
  margin-left: 15px;
}

.my-mr {
  margin-right: 30px;
}

.my-mr-small {
  margin-right: 15px;
}
