@media screen {
  #{ $mysandvik }table {
    $block: &;
    border: none;
    width: 100%;

    > caption {
      font-weight: 200;
    }

    > tbody,
    > thead {
      > tr > td, > tr > th {
        line-height: 1.25em;
      }
      > tr > th {
        padding: 8px 15px 8px 8px;
      }

      > tr > td {
        padding: 15px 15px 15px 8px;
      }
    }

    &--compact {
      > tbody > tr,
      > thead > tr{
        > td, > th {
          padding: 4px;
        }

      }
    }

    > tbody > tr,
    > thead > tr {
      > td, > th {
        a {
          text-decoration: none;
          border: none;
          border-bottom: 1px solid $my-color-border;

          @media screen {
            color: $my-color-text;
          }
          @media print {
            color: #000;
          }

          &:hover {
            @media screen {
              border-bottom-color: $my-color-border-dark;
            }
          }
        }
      }
    }

    > thead {
      border: none;
      > tr > th {
        font-weight: 700;
      }
    }

    > tbody {
      > tr > th, > tr > td {
        border: none;
        font-weight: 200;
        vertical-align: middle;
      }
      > tr > th {
        font-weight: 700;
      }

    }

    &:not(#{$block}--nozebra) {
      > tbody,
      > thead {
          > tr {
          &:nth-child(even) {
            background-color: $my-color-n1;
          }
        }

        &#{$block}--hover {
          > tr:nth-child(even):hover {
            background-color: $my-color-n2;
          }
        }
      }

    }

    &--nozebra {
      > tbody,
      > thead {
        > tr.even {
          background-color: $my-color-n1;
        }
      }
    }

    &--nowrap {
      > tbody, > thead {
        > tr > td, > tr > th {
          white-space: nowrap;
        }
      }
    }

    &--tbody-nowrap {
        > tbody {
          > tr > td, > tr > th {
            white-space: nowrap;
          }
        }
    }


  }

}

@media print {
  #{ $mysandvik }table {

    border-collapse: separate;
    border-spacing: 0;

    td, th {
      font-family: $my-font-family-text;
      text-align: left;
      margin:0;
    }

    border: none;
    width: 100%;
    th {
      text-transform: uppercase;
    }
    td, th {
      line-height: 1.25em;
      padding: 0.15cm;
      vertical-align: top;
    }

    thead {
      tr {
        td, th {
          border-bottom: 1px solid black;
        }
      }
    }

    tbody tr {
      &:not(:last-child) {
        td, th {
          border-bottom: 1px solid black;
        }
      }
    }



  }
}
