@import '../../variables.scss';

/* LOADING ANIMATION */
.loading {
  text-align: center;
}

.modal-fader {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.7;
  z-index: 98;
}

.loading .material-icon {
  font-size: 25px;
}

.modal-fader.dialog {
  background: #444;
  opacity: 0.45;
}

.loading .circle {
  position: relative;
  display: inline-block;
  height: 46px;
  width: 46px;
  margin: 30px;
  border-radius: 50%;
  background: none repeat scroll 0 0 #DDDDDD;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.loading .circle:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 9px;
  display: block;
  height: 28px;
  width: 28px;
  background: none repeat scroll 0 0 #F2F2F2;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loading .circle > span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.loading .circle .anim {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 $my-color-cb;
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left 3s infinite;
  animation: ui-spinner-rotate-left 3s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 3s;
  animation-timing-function: linear;
}

.loading .circle .left {
  left: 0;
}

.loading .circle .left .anim {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.loading .circle .right {
  left: 50%;
}

.loading .circle .right .anim {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
  -webkit-animation-name: ui-spinner-rotate-right;
  -webkit-animation-delay: 0;
  -webkit-animation-delay: 1.5s;
  animation-name: ui-spinner-rotate-right;
  animation-delay: 0;
  animation-delay: 1.5s;
}

@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(182deg);
  }
  75% {
    transform: rotate(182deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(359deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

/* LOADING ANIMATION END */
/* LOADING ANIMATION NEW */
.load {
  position: relative;
}

.loading-new {
  text-align: center;
}

.loading-new {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

}
.appLoaderProgress {
  text-align: center;
  display: block;
}

.fullscreen > .loading-new {
height: 100%;
}

.loading-new > div {
  align-self: center;
}

[data-loader-for] .loading-new {
  height: 100%;
}


.loading-new .circle {
  position: relative;
  display: inline-block;
  height: 46px;
  width: 46px;
  margin: 10px;
  border-radius: 50%;
  background: none repeat scroll 0 0 #ddd;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.loading-new .circle:after {
  content: "";
  position: absolute;
  top: 9px;
  left: 9px;
  display: block;
  height: 28px;
  width: 28px;
  background: none repeat scroll 0 0 #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loading-new .circle > span {
  position: absolute;
  height: 100%;
  width: 50%;
}

.loading-new .circle .anim {
  position: absolute;
  left: 100%;
  top: -4px;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 $my-color-cb;
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left-new 2s infinite;
  animation: ui-spinner-rotate-left-new 2s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-timing-function: linear;
}

.loading-new .circle .left {
  left: 0;
}

.loading-new .circle .left .anim {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

// .loading-small {
//   display: table;
//   width: 100%;
//   height: 100%;
// }

.loading-small > div {
  // display: table-cell;
  vertical-align: middle;

}
.loading-small {
  margin: 0 auto;
}

.tile-loading--inline .loading-small {
  display: block;
}

.tile-loading--inline .loading-small > div {
  display: block;
}

.loading-small {
  display: inline-block;
}

.loading-small .circle {
  position: relative;
  display: inline-block;
  height: 14px;
  width: 14px;
  margin: 0;
  padding: 0 !important;
  border-radius: 50%;
  background: none repeat scroll 0 0 #ddd;
  overflow: hidden;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
  top: 0.2em;
}

.loading-small .circle:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  height: 10px;
  width: 10px;
  background: none repeat scroll 0 0 #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.loading-small.disabled .circle:after  {
  background-color: #f8f8f8;
}

.loading-small .circle > span {
  position: absolute;
  height: 100%;
  width: 50%;
  padding: 0 !important;
}

.loading-small .circle .anim {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 $my-color-cb;
  opacity: 0.8;
  padding: 0 !important;
  -webkit-animation: ui-spinner-rotate-left-new 2s infinite;
  animation: ui-spinner-rotate-left-new 2s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 2s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 2s;
  animation-timing-function: linear;
}

.loading-small .circle .left {
  left: 0;
  margin: 0;
}

.loading-small .circle .left .anim {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@keyframes ui-spinner-rotate-left-new {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes ui-spinner-rotate-left-new {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

.progress-bar {
  position: relative;
  height: 4px;
  margin-right: 40px;
  margin-bottom: 20px;
  background-color: #f8dcc2;
  text-align: left;
}

.progress-bar > div {
  display: inline-block;
  height: 4px;
  background-color: #ff6a00;
  vertical-align: top;
}

.progress-bar > div > span {
  position: absolute;
  top: 2px;
  left: 100%;
  padding-left: 5px;
  color: #ff6a00;
  font-weight: 600;
  font-size: 12px;
  line-height: 0;
  white-space: nowrap;
}

.progress-bar.indeterminate {
  overflow: hidden;
  margin-bottom: 0;
  margin-top: 8px;
}
.progress-bar .indeterminate {
  position: relative;
  animation: progress-indeterminate 3s linear infinite;
  width: 25%;
}

@keyframes progress-indeterminate {
   from { left: -25%; width: 25%; }
   to { left: 100%; width: 25%;}
}

.tile-loading {
  position: relative; text-align:center;
}

.fullscreen {
  cursor: progress;
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,2555, 0.5);
  z-index: 60000;
  padding: 30px;
}
