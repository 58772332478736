@import 'variables.scss';
@import 'mixins/all';
@import './components/ecommerce/util.scss';

@import 'my-sandvik/all';

// We still use Bulma columns and modal styles (possibly something more)
@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/grid/columns.sass';
@import 'bulma/sass/helpers/_all.sass';
@import 'bulma/sass/components/modal.sass';
@import 'bulma/sass/components/level.sass';

@import '../app/components/context-menu/context-menu-ie11hack.component.scss';
@import '../app/directives/filedrop/filedrop.directive.global.scss';

@media screen {
  @import 'quill/dist/quill.core.css';
  @import 'quill/dist/quill.bubble.css';
  @import 'quill/dist/quill.snow.css';

  // Import app container
  @import './components/appcontainer/appcontainer';

  @import './components/loader/loader';

  //ecom specific styles
  @import './components/ecommerce/form/form';

  @import './../app/components/inline-editing-overlay/inline-editing-overlay.global.scss';
  @import './../app/components/responsibility-picker/responsibility-picker-global.scss';





  .is-vcentered {
    align-items: center;
    display: flex;
  }

  .text-success {
    color: $my-color-success !important;
    font-weight: bold;
  }

  .text-warning {
    color: #ff6a00 !important;
    font-weight: bold;
  }

  .text-error {
    text-transform: uppercase;
    color: $my-color-error !important;

    &--nocaps {
      text-transform: none;
      ;
    }
  }


  .notice-content .text ul li {
    margin-bottom: 0 ! important;
  }

  .noticebar {
    h2 {
      margin-bottom: 10px;
      color: $my-color-text;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      text-transform: none;
    }

    pre {
      max-width: 987px;
    }
  }

  .offline .offline-dim {
    filter: grayscale(35%);
  }

  .system-message {
    &.error-message {
      h2 {
        color: $my-color-error;
        font-weight: 400;
        font-size: 18px ! important;
        text-transform: none ! important;
        margin-bottom: 10px ! important;
      }

      p {
        margin-bottom: 26px ! important;
      }
    }
  }

}

mark  {
  background-color: #ffff0080;
  color: inherit;
}

mark + mark {
  background-color: rgb(221 231 34 / 40%);
  color: inherit;
}

body.has-topbar {
  margin-top: 72px;
}

@media (max-width: 1199px) {
  .noticebar pre {
    white-space: normal !important;
  }

}

@media all and (min-width: 1200px) {
  body.has-topbar {
    &.browser-notice-visible:not(.is-logged-in) {
      margin-top: 115px;
    }

    &.is-logged-in {
      margin-top: 100px;

      &.browser-notice-visible {
        margin-top: 142px;
      }
    }
  }
}

body.no-scroll {
  overflow: hidden;
}

@media screen {
  .print-only {
    display: none ! important;
  }
}

body {
  -webkit-overflow-scrolling: touch;
}

@media print {
  html,
  body {
    margin: 0;
  }

  html,
  body,
  p,
  li,
  ul,
  div,
  span,
  strong,
  dl,
  dt,
  small {
    font-family: Serif, 'Times new roman'; //Kept old fonts for print in Caecum 1.0 (after discussion with Christoffer)
    font-size: .85rem;
    color: #000 ! important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #000 ! important;
  }

  .ecommerce.header,
  .group.footer,
  .tab-navigation,
  .refiner-sidebar,
  .hide-print {
    display: none ! important;
  }

  #main {
    margin-top: 0 ! important;
  }

  .dialog-open {
    #main {
      display: none;
    }

    .modal-window {
      top: 0 ! important;

      &.dialog {
        margin-top: 0 ! important;

        .lightBoxDialog {
          width: auto;
        }
      }
    }
  }

  .embeddedServiceHelpButton {
    display: none ! important;
  }
}

.loading--line {
  display: inline-block;
  position: absolute;
  top: 6px;
  animation: pulse 1s infinite ease-in-out;
  width: 60px;
  height: 0.85em;
  margin-top: 0em;
  margin-bottom: 0em;
}

.uppercase {
  text-transform: uppercase;
}

H1.miningElement-H1 {
  -ms-name: "Heading 1";
  font-family: $my-font-family-text;
  line-height: 1.2em;
  font-size: 1.8em !important;
  font-weight: 300;
  display: block;
  color: $my-color-text;
  margin-bottom: 10px;
}

.body {
  font-size: 1rem;

  p {
    margin-bottom: 0.5em;
  }
}

H2.miningElement-H2 {
  -ms-name: "Heading 2";
  font-family: $my-font-family-text;
  line-height: 1.2em;
  font-size: 1.2em !important;
  font-weight: 300;
  display: block;
  color: $my-color-text;
  margin-bottom: 10px;
}

.align-right {
  text-align: right;
}

.scope {
  background-color: #eaeaea;
  padding: 2px;
  border-radius: 3px;
}

.mt-2 {
  margin-top: 1.5em ! important;
  display: block;
}

.mt-3 {
  margin-top: 2em ! important;
  display: block;
}

.mt-5 {
  margin-top: 3em ! important;
  display: block;
}

.form-control--block {
  display: block;
  width: 100%;

}

.dropdown-item-tag {
  color: #fff;
  background-color: $my-color-eb;
  border-radius: 3px;
  padding: 2px 4px;

  &--discreet {
    background-color: #eaeaea;
    color: #333;
    margin-left: 10px;
  }

  &--secondary {
    background-color: $my-color-background-secondary;
    color: $my-color-text;
    padding: 1px 2px;
    font-size: 0.7em;
    margin-left: 5px;
    border-radius: 3px;
  }
}

.dropdown-item-help-block {
  margin: 4px 0 10px 0;
  color: #777;
  font-size: .9em;

}

.ql-container div.ql-mibp-section-separator {
  color: $my-color-text;
  position: relative;
  font-size: 18px !important;
  font-family: $my-font-family-text;
  line-height: 1.5rem;
  font-weight: 100;
  padding-left: 10px;
  margin: 0.75em;
  text-transform: none;

  &:before {
    position: absolute;
    bottom: 0;
    top: -3px;
    left: -6px;
    width: 8px;
    height: 32px;
    background: url(/assets/images/arrow-right-orange-11x20.svg) 0 50% no-repeat;
    background-size: 7px 16px;
    content: "";
    transition: transform 162ms ease;
    transform: rotate(90deg);
  }

  &:empty {
    background-color: black;
  }
}

// To ensure datetime picker works in modals
.cdk-overlay-container {
  z-index: 90000;
}

// There should probably be a general styling for inline help messages
// I know we do this in many other places as well
.order-date-sent-notice {
    padding: 10px;
    strong {
      font-weight: 700;
    }
}


.show-in-new-window-link {
  cursor: pointer;

  &__icon {
    vertical-align: middle;
    margin-right: 5px;
    fill: $my-color-cb;
    width: 26px;
    height: 26px;
  }
}

.click-to-copy {
  position: relative;
  transition: all 0.2s;
  &--copied {
    background-color: yellow;
  }
}


@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/** Begin "Backend down during deploy" message */
.error-maintenance-window {
  background-color: rgba(255,255,255,0.95);
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 390000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $my-font-family-text;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out;
  &--visible {
    opacity: 1;
  }

  img {
    height: 30px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  > div {
    width: 500px;
    border: 2px solid $my-color-eb;
    background: #fff;
    padding: 15px;
    text-align: center;
  }
  .material-icon {
    text-align: center;
    font-size: 3em;
  }
  h1 {
    @include mysandvik-h3();
  }
  p {
    margin-bottom: 0.5em;
  }

}
/** End "Backend down during deploy" message */
