.mibp-filedrop {
  border: 1px solid transparent;
  position: relative;

  &--hover {
    border: 1px solid #09f ! important;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #e1f2ffaa;
    z-index: 10000;
  }

}
