@import '../../../variables.scss';

.tiles {}

.tiles__row,
.tiles .row {
    clear: both;
    overflow: hidden;
}

.tiles__row > div,
.tiles .row > div {
  &:first-of-type {
    margin-left: 0;
  }
}
//@include responsive-lt-medium() {
//    .tiles__row > div,
//    .tiles .row > div {
//      &:first-of-type {
//        margin-left: 36px;
//      }
//    }
//    #main-dashboard {
//        width: 700px;
//    }
//}



@media only screen and (min-width: 569px) and (max-width: 1179px) {
    #main-dashboard.tiles__row > div,
    #main-dashboard .row > div {
      &:first-of-type {
        margin-left: 36px;
      }
    }
    #main-dashboard {
        //width: 700px;
       // margin-left: -36px;
    }
}

@media screen and (max-width: 568px) {
    #main-dashboard {
        width: 100%;
        margin-left: auto;
    }
}



@media (max-width: 768px) {
    #main-dashboard.tiles__row > div {
            margin-left: auto !important;
            margin-right: auto !important;
            float: none;
    }
    #main-dashboard {
        width: 100%;
    }
}



.tiles__row--search {
  overflow: visible;
}
.tiles__row--search.hidden {
  display: none;
}

.tiles__column,
.tiles .column {
  min-height: 1px;
}
.tiles .column {
  background-color: pink;
}

.tiles--size1,
.tiles--size2,
.tiles--size3,
.tiles--size4 {
  margin-left: 36px;
  float: left;
}
.tiles--size1 {
    width: 268px;
}
.tiles--size2 {
    width: 572px;
}
.tiles--size3 {
    width: 876px;
}
.tiles--size4 {
  width: 100%;
}

.quick-add__item {
    margin-left: 0 !important;
}

/*@media screen and (min-width: 768px) {
    .tiles__column.tiles--size2.quick-add-column.ng-scope {
        float: none;
        margin-left: 0;
    }
}*/


// @media screen and (max-width: 767px) and (min-width: ) {
// @include responsive_min_max(376px, 420px)
// {
//             #quick-add-tile { .quick-add__parts-number, .quick-add__template { width: 53% } }
// }

// @include responsive_min_max(360px, 375px)
// {
//             #quick-add-tile { .quick-add__parts-number, .quick-add__template { width: 50% } }
// }


// @include responsive_min_max(320px, 359px)
// {
//             #quick-add-tile { .quick-add__parts-number, .quick-add__template { width: 43% } }
// }

@media screen and (max-width: 767px) {
    /*.tiles--size1,
    .tiles--size2,
    .tiles--size3,
    .tiles--size4 {
      margin-left: 0;
    }*/
    /*.quick-add-column {
      margin-left: 0;
    }*/
    .tiles--size2 {
        width: 100%;
    }
    #quick-add-tile {
        .tabs {
            padding-bottom: 20px;
            ul {
                text-align: center;
            }
            a {
    //            float: none;
    //            display: block;
            }
        }
        .tabs a {
            border: none;
            border-bottom: 3px solid transparent;
            float: none;
            display: inline-block;
            margin-top: 2px;
        }
        .tabs a:after, .tabs a:before {
            border: none;
        }
        .tabs .active a {
            border-bottom: 3px solid $my-color-vo;
        }
        .tabs li.active a {
            top: 0;
        }
    }


}



/* TODO: Remove */
.tiles .size1 {
    width: 268px;
    margin-left: 36px;
    float: left;
    background-color: pink;
}
.tiles .size2 {
    width: 572px;
    margin-left: 36px;
    float: left;
    background-color: purple;
}
.tiles .size3 {
    width: 876px;
    margin-left: 36px;
    float: left;
    background-color: yellow;
}

.tile {
    box-sizing: border-box;
    margin-top: 36px;
    border: 1px solid #9c9c9c;
    overflow: hidden;
    padding: 18px;
    max-width: 100%;
    position: relative;
}

.tile.no-float {
    float: none;
}

.tile--clean {
  border: 0;
  padding: 0;
}

.tile--no-padding,
.tile.no-padding {
    padding: 0;
}

.tile--no-border,
.tile.no-border {
    border: 0;
}

.tile--no-margin,
.tile.no-margin {
    margin: 0 !important;
}

.tile--blue,
.tile.blue {
    border: 1px solid $my-color-eb;
    background-color: $my-color-eb;
}

.tile--orange,
.tile.orange {
    border: 1px solid $my-color-vo;
    background-color: $my-color-vo;
}

.tile--orange-white,
.tile.orange-white {
  background: #fff;
  border: 1px solid $my-color-vo;
  & h2 {
    color: $my-color-vo;
  }
  & h3 {
    color: $my-color-vo;
  }
}

.tile__title {
  /*color: $my-color-eb;*/
  margin: 18px 0 18px 18px;
  & a {
    /*color: inherit;*/
  }
  & i {
    margin-right: 0.5em;
  }
}
.tile--clean .tile__title {
  margin-left: 0;
}

.tile__header,
.tile-header {
  position: relative;
  & a {
    color: inherit;
    float: right;
    &:hover {
      color: #333;
    }
  }
  .tile-menu-button {
      &--warning,
      &--warning:hover {
          color: $my-color-vo;
          margin-right: 10px;
      }
  }
}

.tile__body,
.tile-body {
    padding: 0 18px;
}

.tile__footer,
.tile-footer {
    margin-top: 18px;
    text-align: right;
}

/* tile-fader */
.tile-fader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    opacity: 0;
    transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
}

.tile-fader.active {
    opacity: 0.75;
}

.tile-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // compiler stops here, commented out
    //    &--inline {
    //        position:relative;
    //    }
}

/* TILE TYPES */
/* dashboard */
@import "tile_dashboard";

/* chart-dashboard */
@import "tile_chart-dashboard";

/* fleet */
@import "tile_fleet";

/* cart-details */
@import "tile_cart-details";

/* my profile */
.profile-photo-tile {}
.profile-photo-tile__image-wrapper {
  padding: 18px;
  border: 1px solid #9c9c9c;
  & img {
    width: 100%;
  }
}

.image-tile {
  background: #f5f5f5;
  border: none;
  & img {
    width: 100%;
  }
}

/* ays login promo */
.tile.auth-promo {
    margin-top: 130px;
}

    .tile.auth-promo > div {
        display: table;
        width: 100%;
    }

        .tile.auth-promo > div > div {
            display: table-cell;
            width: 33%;
            text-align: center;
        }

          .tile.auth-promo > div > div:last-child {
              width: 34%;
          }

    .tile.auth-promo .num {
        font-size: 30px;
        color: #555;
    }

    .tile.auth-promo .desc {
        font-size: 14px;
        text-transform: uppercase;
        color: #999;
    }
/* END TILE TYPES */

/* CUSTOMIZATION */
/* maintenance tool */
#maintenance-tool-dummy {
    margin: 18px;
    margin-bottom: 3 6px;
    padding: 36px;
    height: 150px;
    background-color: #f2faff;
    border: 1px solid #7fccff;
    font-size: 2em;
    color: #cacaca;
}

#tile-search {
    overflow: visible;
}

    #tile-search .search {
        box-sizing: border-box;
        display: table-cell;
        width: auto;
        height: 40px;
        background-color: #f2faff;
        border: 1px solid #7fccff;
        font-size: 17px;
        color: #fff;
        padding: 0 10px;
    }

    #tile-search input[type="text"] {
        width: 100%;
        height: 38px;
        background-color: #f2faff;
        border: none;
        font-size: 17px;
        color: #222;
    }

    #tile-search .table {
        position: relative;
    }

    #tile-search .container {
        display: table-cell;
        width: 15%;
        min-width: 90px;
        padding-left: 18px;
    }

    #tile-search button {
        width: 100%;
        height: 46px;
        background-color: #fff;
        border: 1px solid #ff6a00;
        font-size: 12px;
        text-transform: uppercase;
        color: #ff6a00;
        cursor: pointer;
    }

/* act as */
#act-as-tile .column {
    float: left;
}

#act-as-tile .column-1 {
    width: 80%;
}

#act-as-tile .column-2 {
    text-align: right;
    width: 20%;
}

/* search view */
#search-view .tile__header {
  text-align: right;
  & .sort {
    & label {
      display: inline-block;
      margin-right: 18px;
    }
    & > div {
      display: inline-block;
      width: 167px;
    }
  }
}

#search-view .tab-content .tile {
    margin-top: 0;
}

/* order confirmation */
#order-confirmation p {
  background-color: #EAEAEA;
  text-transform: uppercase;
  padding: 18px;
}
/* END CUSTOMIZATION */

/* MODAL CHECKOUT */
.modal-checkout h3 {
    margin-bottom: 20px;
}
/* END MODAL CHECKOUT */

/* tile menu */
.tile.orange .tile-menu {
    border-color: #f36a22;
    &:before {
        content: url('/src/assets/images/tile-menu-arrow-orange.png');
    }
}

.tile.orange .tile-menu ul li a:hover {
    background-color: #f36a22;
}

.tile-menu {
    position: absolute;
    top: 40px;
    right: -10px;
    min-width: 150px;
    background: #fff;
    border: 1px solid $my-color-border;
    display: none;
    box-shadow: 0 0 0 2px #fff;
    z-index: 300;
    &:before {
      content: url('/src/assets/images/tile-menu-arrow-blue.png');
      display: block;
      position: absolute;
      top: -13px;
      right: 11px;
      width: 19px;
      height: 10px;
    }
}

    .tile-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

        .tile-menu ul li {
            display: block;
            clear: both;
        }

          .tile-menu ul li a {
              display: block;
              padding: 18px;
              box-sizing: border-box;
              width: 100%;
              color: #000;
              &:hover {
                text-decoration: none;
                color: #fff;
                background-color: #09f;
                box-sizing: border-box;
              }
          }


//@include responsive_min_max(769px, 1268px) {


  @media (min-width: 769px) and (max-width: 1179px) {
    .tiles__row > div:nth-of-type(3),
  .tiles .row > div:nth-of-type(3) {
      margin-left: 0;
  }

  .tiles .size1,
  .tiles--size1 {
      width: 48%;
      margin-left: 4%;
  }

  .tiles .size2,
  .tiles--size2 {
      width: 48%;
      margin-left: 4%;
  }

  .tiles .size3,
  .tiles--size3 {
      width: 100%;
  }

  .tiles--size1 + .tiles--size3,
  .tiles--size3 + .tiles--size1,
  .tiles .size3 + .size1,
  .tiles .size1 + .size3,
  .tiles--size1 + .tiles--size3,
  .tiles--size3 + .tiles--size1 {
      width: 100%;
      margin-left: 0;
  }
}

@media (max-width: 768px) {
  #tile-search {
      padding: 0 !important;
      border: 0 !important;
  }

  #tile-search .search-tags {
      display: none;
  }

  #tile-search input[type="text"] {
      font-size: 14px;
      padding: 5px 2px;
  }

  .tiles,
  .tiles__column {
      width: 100%;
      margin: 0;
  }

  .tile {
    float: none !important;
    margin: 18px 0 0 0 !important;
    width: auto !important;
    & h2 {
      font-size: 26px;
      margin-left: 0 !important;
    }
    & > h3 {
      margin-left: 0;
    }
  }

  .tile__title {
    margin-left: 0;
  }

  .tile__body,
  .tile .tile-body {
      padding: 0 !important;
  }
}
